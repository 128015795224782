/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * External dependencies
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

/**
 * Internal dependencies
 */

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import BasicLayout from 'components/BasicLayout';

import FormField from '../../components/FormField';
import SocialSignIn from './components/SocialSignIn';
import PasswordField from '../sign-up/components/PasswordField';

import bgImage from 'assets/images/bg-xstream.png';

import useUserLogIn from '../../utils/hooks/use-userLogIn';
import useAuthContext from '../../authentication/use-AuthContext';

// Formik
import { Form, Formik } from 'formik';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import form from './schemas/form';
import { toast } from 'react-toastify';

function SignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const {
    formId,
    formField: { email, password },
  } = form;

  const { loginUser } = useUserLogIn();
  const { setSessionToken } = useAuthContext();
  const navigate = useNavigate();

  const handleLogin = (values, actions) => {
    const { email, password } = values;
    loginUser({
      variables: {
        input: { email, password },
      },
    })
      .then(({ data }) => {
        if (data) {
          const token = data.loginUser.loginToken;
          if (token) {
            setSessionToken(token);
            actions.setSubmitting(false);
            actions.resetForm();
            if (data.loginUser.registrationWizard) {
              navigate('/pages/user_profile', { replace: true });
            } else {
              navigate('/user-wizard', { replace: true });
            }
          }
        }
      })
      .catch((err) => {
        actions.setSubmitting(false);
        actions.resetForm();
        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: '280px', minHeight: '620px' }}>
        <SocialSignIn title="XStream Traffic" description="Sign in" />
        <MDBox pt={4} pb={3} px={2}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={validations}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.toLowerCase());
                  }}
                />
                <PasswordField
                  formData={{
                    formField: { email, password },
                    values,
                    touched,
                    errors,
                  }}
                />
                <MDBox display="flex" alignItems="center" ml={-1} my={2.5}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
                <MDButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="gradient"
                  color="primary"
                  fullWidth
                >
                  sign in
                </MDButton>
              </Form>
            )}
          </Formik>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?&nbsp;
              <MDTypography
                component={Link}
                to="/sign-up"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Sign up
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Forgot your password?&nbsp;
              <MDTypography
                component={Link}
                to="/reset-password"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Reset Password
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
