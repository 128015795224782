import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import React from 'react';

function ShieldMini({ title, tColor, tVariant }) {
  return (
    <MDBox
      my={-1.5}
      mx={3}
      bgColor="primary"
      borderRadius="lg"
      minHeight="50px"
      textAlign="center"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MDTypography variant={tVariant} color={tColor} fontWeight="regular">
        {title}
      </MDTypography>
    </MDBox>
  );
}

export default ShieldMini;
