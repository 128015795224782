/* eslint-disable import/no-anonymous-default-export */
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import checkout from './form';
import {
  adultLimitDate,
  getArrDate,
  getStringDate,
} from '../../../utils/dateUtils/dateUtils';
import { avatarImg } from '../../../utils/data/Fill-InAndOtherData';

const [DD, MM, YYYY] = getArrDate(getStringDate(adultLimitDate));

const {
  formField: {
    username,
    gender,
    birthDate,
    day,
    month,
    year,
    avatar,
    blockCountries,
    workingSites,
    instagram,
    twitter,
    snapchat,
    onlyfans,
  },
} = checkout;

export default {
  [username.name]: '',
  [gender.name]: 'FEMALE',
  [birthDate.name]: getStringDate(adultLimitDate),
  [day.name]: DD,
  [month.name]: MM,
  [year.name]: YYYY,
  [avatar.name]: avatarImg[0],
  [blockCountries.name]: [],
  [workingSites.name]: [],
  [instagram.name]: '',
  [twitter.name]: '',
  [snapchat.name]: '',
  [onlyfans.name]: '',
};
