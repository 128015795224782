import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useState } from 'react';
import ModalAllTransactions from './ModalAllTransactions';
import TransactionsList from './TransactionsList';
import { useUserTransactions } from '../../../../utils/queries/queries';
import LoadingBasic from '../../../../components/LoadingBasic';
import {
  isFromToday,
  wasYesterday,
  isOlderThanYesterday,
} from '../../utils/dateUtils';
import { Stack } from '@mui/material';
import { walletButtonsStyle } from '../../utils/styling';

function TransactionsCard() {
  const { data: { allTransactionsUser: transactions } = {} } =
    useUserTransactions();

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card sx={{ boxShadow: 4 }}>
        <Stack direction={'row'} justifyContent="space-between" mt={3} mx={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Your Transactions
          </MDTypography>
          <MDButton {...walletButtonsStyle} onClick={() => setShowModal(true)}>
            view all
          </MDButton>
        </Stack>
        <MDBox pt={3} pb={2} px={2}>
          {!transactions ? (
            <LoadingBasic />
          ) : (
            <>
              <TransactionsList
                title={'Today'}
                transactions={transactions
                  .filter((item) => isFromToday(item.date))
                  .reverse()}
              />
              <TransactionsList
                title={'Yesterday'}
                transactions={transactions
                  .filter((item) => wasYesterday(item.date))
                  .reverse()}
              />
              <TransactionsList
                transactions={transactions
                  .filter((item) => isOlderThanYesterday(item.date))
                  .reverse()}
                title={'Older'}
              />
            </>
          )}
        </MDBox>
      </Card>
      <ModalAllTransactions modalStatus={showModal} setModal={setShowModal}>
        {transactions && (
          <TransactionsList
            transactions={[...transactions].reverse()}
            title={'All transactions'}
            short={false}
          />
        )}
      </ModalAllTransactions>
    </>
  );
}

export default TransactionsCard;
