/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { InputAdornment, Stack } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import FormField from 'components/FormField';

function Socials({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { instagram, twitter, snapchat, onlyfans } = formField;
  const {
    instagram: instagramV,
    twitter: twitterV,
    snapchat: snapchatV,
    onlyfans: onlyfansV,
  } = values;

  return (
    <MDBox>
      <MDBox width="80%" sx={{ mx: { xs: 2, sm: 4 } }} my={4}>
        <MDBox mb={{ xs: 4, sm: 8 }}>
          <MDTypography variant="h5" fontWeight="regular">
            Socials
          </MDTypography>
        </MDBox>
      </MDBox>
      <Stack sx={{ pl: { xs: 2, sm: 4 }, pr: { xs: 2, sm: 4 } }}>
        <FormField
          sx={{ '& .MuiInputBase-input': { fontSize: '17px' } }}
          type={instagram.type}
          label={''}
          name={instagram.name}
          value={instagramV}
          placeholder={instagram.placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ minWidth: 75 }}>
                <MDTypography variant="button">{`${instagram.label}`}</MDTypography>
              </InputAdornment>
            ),
          }}
        />
        <FormField
          sx={{ '& .MuiInputBase-input': { fontSize: '17px' } }}
          type={twitter.type}
          label={''}
          name={twitter.name}
          value={twitterV}
          placeholder={twitter.placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ minWidth: 75 }}>
                <MDTypography variant="button">{`${twitter.label}`}</MDTypography>
              </InputAdornment>
            ),
          }}
        />
        <FormField
          sx={{ '& .MuiInputBase-input': { fontSize: '17px' } }}
          type={snapchat.type}
          label={''}
          name={snapchat.name}
          value={snapchatV}
          placeholder={snapchat.placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ minWidth: 75 }}>
                <MDTypography variant="button">{`${snapchat.label}`}</MDTypography>
              </InputAdornment>
            ),
          }}
        />
        <FormField
          sx={{ '& .MuiInputBase-input': { fontSize: '17px' } }}
          type={onlyfans.type}
          label={''}
          name={onlyfans.name}
          value={onlyfansV}
          placeholder={onlyfans.placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ minWidth: 75 }}>
                <MDTypography variant="button">{`${onlyfans.label}`}</MDTypography>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </MDBox>
  );
}

export default Socials;
