/* eslint-disable no-unused-vars */
/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Images
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import selectData from 'utils/data/selectData';

import useAllCountries from '../../../../utils/hooks/use-allCountries';
import {
  adultAge,
  getArrDate,
  getStringDate,
  getYears,
} from '../../../../utils/dateUtils/dateUtils';
import { avatarImg } from '../../../../utils/data/Fill-InAndOtherData';
import Avatar from '@mui/material/Avatar';
import MDInput from 'components/MDInput';
import FormField from 'components/FormField';

const lod_str = require('lodash/string');

function UserInfo({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { username, birthDate, gender, avatar, blockCountries } = formField;
  const {
    username: usernameV,
    gender: genderV,
    birthDate: birthDateV,
    avatar: avatarV,
    blockCountries: blockCountriesV,
  } = values;
  const countries = useAllCountries()?.data?.allCountries ?? [];

  const [DD, MM, YYYY] = getArrDate(birthDateV);

  const setBorder = (avatar) => {
    return avatarV === avatar ? 3 : 0;
  };

  return (
    <MDBox>
      <MDBox width="80%" sx={{ mx: { xs: 2, sm: 4 } }} my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Let&apos;s start with the basic information
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          Set up a username and your account detail
        </MDTypography>
      </MDBox>
      <MDBox mt={2} sx={{ pl: { xs: 2, sm: 4 }, pr: { xs: 2, sm: 4 } }}>
        <MDBox sx={{ width: { xs: '100%', sm: '100%' } }}>
          <FormField
            type={username.type}
            label={username.label}
            name={username.name}
            value={usernameV}
            // placeholder={username.placeholder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBoxIcon fontSize={'medium'} />
                </InputAdornment>
              ),
            }}
          />
          <MDBox mt={0.5} mb={-0.5}>
            <MDTypography variant="h6" color="secondary">
              I&apos;m
            </MDTypography>
          </MDBox>
          <Autocomplete
            disableClearable
            options={selectData.genders}
            getOptionLabel={(option) => lod_str.capitalize(option)}
            onChange={(e, newValue) => {
              setFieldValue('gender', newValue);
            }}
            value={genderV}
            renderInput={(params) => (
              <MDInput {...params} variant="standard" label="" />
            )}
          />
          <MDBox mt={0.5} mb={-0.5}>
            <MDTypography variant="h6" color="secondary">
              Birth Date
            </MDTypography>
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Autocomplete
                disableClearable
                options={selectData.months}
                value={selectData.months[MM]}
                onChange={(e, newValue) => {
                  setFieldValue(
                    'birthDate',
                    getStringDate(
                      new Date(YYYY, selectData.months.indexOf(newValue), DD)
                    )
                  );
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label=""
                    FormHelperTextProps={{ style: { color: 'red' } }}
                    helperText={
                      !errors?.birthDate ? ' ' : `At least ${adultAge} years!`
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                disableClearable
                options={selectData.days}
                value={DD.toString()}
                onChange={(e, newValue) => {
                  setFieldValue(
                    'birthDate',
                    getStringDate(new Date(YYYY, MM, parseInt(newValue, 10)))
                  );
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="" />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                disableClearable
                options={getYears()}
                value={YYYY.toString()}
                onChange={(e, newValue) => {
                  setFieldValue(
                    'birthDate',
                    getStringDate(new Date(parseInt(newValue, 10), MM, DD))
                  );
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={4} sm={4}>
              <MDTypography variant="h6" color="secondary">
                Select avatar
              </MDTypography>
            </Grid>
            <Grid item container xs sm rowSpacing={1}>
              {avatarImg.map((avatar) => {
                return (
                  <Grid key={avatar} item xs={6} sm={4}>
                    <Avatar
                      src={avatar}
                      alt="avatar picture"
                      sx={{
                        width: 50,
                        height: 50,
                        border: setBorder(avatar),
                        borderColor: 'primary.main',
                      }}
                      onClick={() => {
                        setFieldValue('avatar', avatar);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </MDBox>
        <Grid container mt={1} sx={{ width: { xs: '100%', sm: '100%' } }}>
          <Grid item xs={3}>
            <MDTypography variant="h6" color="secondary">
              Block countries
            </MDTypography>
          </Grid>
          <Grid item xs>
            <Autocomplete
              multiple
              options={countries.map((country) => country.label)}
              value={blockCountriesV}
              onChange={(e, newValue) => {
                setFieldValue('blockCountries', newValue);
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="" />
              )}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default UserInfo;
