import React, { useEffect } from 'react';
import Loading from '../user_profile/loading';
import { useNavigate, useParams } from 'react-router-dom';
import useVerifyEmail from '../../utils/hooks/use-verifyEmail';
import useAuthContext from '../../authentication/use-AuthContext';
import { toast } from 'react-toastify';

function VerifyEmail() {
  const { token: verifyToken } = useParams();
  const { verifyEmail } = useVerifyEmail();
  const { setSessionToken } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmail({
      variables: { token: verifyToken },
    }).then(({ data }) => {
      const authToken = data?.verifyEmail;
      setSessionToken(authToken);
      navigate('/user-wizard', { replace: true });
      if (authToken) {
        toast.success('Email verified!');
      } else {
        toast.error('Invalid token!');
      }
    });
  }, []);

  return <Loading />;
}

export default VerifyEmail;
