/* eslint-disable no-unused-vars */
import React from 'react';
import * as Yup from 'yup';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Autocomplete, Grid, SvgIcon } from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { Form, Formik } from 'formik';
import FormField from 'components/FormField';
import MDButton from 'components/MDButton';
import { useEditUser } from 'utils/mutations/mutations';
import selectData from 'utils/data/selectData';
import {
  adultAge,
  adultLimitDate,
  getArrDate,
  getStringDate,
  getYears,
} from 'utils/dateUtils/dateUtils';
import { sites } from 'utils/data/Fill-InAndOtherData';
import useAllCountries from 'utils/hooks/use-allCountries';
import lod_str from 'lodash/string';
import { ReactComponent as Instagram } from 'utils/logos/instagram-alt.svg';
import { ReactComponent as SnapChat } from 'utils/logos/snapchat-square-2.svg';
import { ReactComponent as OnlyFans } from 'utils/logos/Onlyfans-icon-rounded.svg';

const SignInSchema = Yup.object().shape({
  username: Yup.string().min(
    5,
    'Username is too short - should be 4 chars minimum'
  ),
  gender: Yup.string().required(),
  birthDate: Yup.string().test((value) => {
    const [DD, MM, YYYY] = getArrDate(value);
    const currentBirthDate = new Date(YYYY, MM, DD);
    return currentBirthDate <= adultLimitDate;
  }),
  blockCountries: Yup.array(),
  workingSites: Yup.array().test((value) => {
    return value.length > 0 && !!value[0];
  }),
  instagram: Yup.string(),
  twitter: Yup.string(),
  snapchat: Yup.string(),
  onlyfans: Yup.string(),
});

const formObject = {
  username: {
    name: 'username',
    label: 'Username',
    type: 'text',
    errorMsg: 'Username is required.',
  },
  gender: {
    name: 'gender',
    label: 'Gender',
    type: 'text',
    errorMsg: 'Gender is required.',
  },
  blockCountries: {
    name: 'blockCountries',
    label: 'Block Countries',
    type: 'text',
    errorMsg: 'Block countries is required.',
  },
  workingSites: {
    name: 'workingSites',
    label: 'Sites',
    type: 'text',
    errorMsg: 'Sites is required.',
  },
  instagram: {
    name: 'socialProfile.instagram',
    label: 'Instagram',
    type: 'text',
    errorMsg: 'Instagram is required.',
  },
  twitter: {
    name: 'socialProfile.twitter',
    label: 'Twitter',
    type: 'text',
    errorMsg: 'Twitter is required.',
  },
  snapchat: {
    name: 'socialProfile.snapchat',
    label: 'SnapChat',
    type: 'text',
    errorMsg: 'SnapChat is required.',
  },
  onlyfans: {
    name: 'socialProfile.onlyfans',
    label: 'onlyfans',
    type: 'text',
    errorMsg: 'onlyfans is required.',
  },
};

const itemSpacing = 1.25;

const MyProfileEdit = ({ setIsEdit, userData }) => {
  const {
    username,
    gender,
    blockCountries,
    workingSites,
    instagram,
    twitter,
    snapchat,
    onlyfans,
  } = formObject;

  const { editUser, data, loading, error } = useEditUser();
  const { _id } = userData;

  const initialValues = {
    username: userData.username ?? '',
    birthDate: userData.profile?.birthDate ?? '',
    gender: userData.profile?.gender ?? '',
    blockCountries: userData.blockCountries ?? [],
    workingSites: userData.workingSites ?? [],
    socialProfile: userData.socialProfile
      ? userData.socialProfile
      : {
          instagram: '',
          twitter: '',
          snapchat: '',
          onlyfans: '',
        },
  };

  const countries = useAllCountries()?.data?.allCountries ?? [];

  const handleSubmit = async (values) => {
    const {
      birthDate,
      gender,
      socialProfile: { instagram, twitter, snapchat, onlyfans },
      username,
      blockCountries,
      workingSites,
    } = values;

    const profileEdited = {
      username,
      blockCountries,
      workingSites,
      profile: {
        gender,
        birthDate,
      },
      socialProfile: {
        instagram,
        twitter,
        snapchat,
        onlyfans,
      },
    };
    const res = await editUser({
      variables: { id: _id, input: profileEdited },
    });
    if (res) {
      setIsEdit(false);
    }
  };

  return (
    <MDBox mx={{ xs: 3, md: 6 }} mt={5} mb={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { values, errors, touched, isValid, dirty, setFieldValue } =
            formik;
          const [DD, MM, YYYY] = getArrDate(values.birthDate);
          return (
            <Form>
              <Grid container rowSpacing={1.5}>
                {/* USERNAME AREA */}
                <Grid item xs={12}>
                  <FormField
                    disabled
                    type={username.type}
                    label={username.label}
                    name={username.name}
                    value={values.username}
                    placeholder={username.placeholder}
                    error={errors.username && touched.username}
                  />
                </Grid>
                {/* GENDER AREA */}
                <Grid item container spacing={itemSpacing}>
                  <Grid item xs={12}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                      }}
                    >
                      I'm
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disableClearable
                      options={selectData.genders}
                      getOptionLabel={(option) => lod_str.capitalize(option)}
                      onChange={(e, newValue) => {
                        setFieldValue('gender', newValue);
                      }}
                      value={values.gender}
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="" />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* BIRTHDATE AREA */}
                <Grid item container spacing={itemSpacing}>
                  <Grid item xs={12}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                      }}
                    >
                      Birth Date
                    </MDTypography>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={5}>
                      <Autocomplete
                        disableClearable
                        options={selectData.months}
                        value={selectData.months[MM]}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            'birthDate',
                            getStringDate(
                              new Date(
                                YYYY,
                                selectData.months.indexOf(newValue),
                                DD
                              )
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            variant="standard"
                            label=""
                            FormHelperTextProps={{ style: { color: 'red' } }}
                            helperText={
                              !errors?.birthDate
                                ? ' '
                                : `At least ${adultAge} years!`
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        disableClearable
                        options={selectData.days}
                        value={DD.toString()}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            'birthDate',
                            getStringDate(
                              new Date(YYYY, MM, parseInt(newValue, 10))
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        disableClearable
                        options={getYears()}
                        value={YYYY.toString()}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            'birthDate',
                            getStringDate(
                              new Date(parseInt(newValue, 10), MM, DD)
                            )
                          );
                        }}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" label="" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* COUNTRIES AREA */}
                <Grid item container xs={12} spacing={itemSpacing}>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                      }}
                    >
                      Block Countries
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      multiple
                      value={values.blockCountries}
                      name={blockCountries.name}
                      options={countries.map((country) => country.label)}
                      onChange={(e, value) => {
                        setFieldValue(
                          'blockCountries',
                          value !== null ? value : initialValues.blockCountries
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          label=""
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* SITES AREA */}
                <Grid item container xs={12} spacing={itemSpacing}>
                  <Grid item xs={12} sm={4}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                      }}
                    >
                      Sites
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      multiple
                      value={values.workingSites}
                      options={sites.map((site) => site.label)}
                      onChange={(e, value) => {
                        setFieldValue(
                          'workingSites',
                          value !== null ? value : initialValues.workingSites
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          variant="standard"
                          name={workingSites.name}
                          label=" "
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          FormHelperTextProps={{ style: { color: 'red' } }}
                          helperText={
                            !errors?.workingSites
                              ? ' '
                              : `At least one working site required!`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* SOCIAL MEDIA AREA */}
                <Grid item container xs={12} spacing={itemSpacing}>
                  <Grid item xs={12}>
                    <MDTypography
                      fontWeight="bold"
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        color: '#7B809A',
                      }}
                    >
                      Social Media Links
                    </MDTypography>
                  </Grid>
                  <Grid item container xs={12} columnSpacing={4}>
                    {/* INSTAGRAM */}
                    <Grid item xs={12} sm={6}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <SvgIcon
                          sx={{
                            width: '2em',
                            height: '2em',
                            mr: 1,
                          }}
                          color={'primary'}
                        >
                          <Instagram />
                        </SvgIcon>
                        <FormField
                          type={instagram.type}
                          label={instagram.label}
                          name={instagram.name}
                          value={values?.socialProfile?.instagram}
                          placeholder={instagram.placeholder}
                        />
                      </MDBox>
                    </Grid>
                    {/* TWITTER */}
                    <Grid item xs={12} sm={6}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <TwitterIcon
                          sx={{
                            color: '#E91F63',
                            width: '2em',
                            height: '2em',
                            mr: 1,
                          }}
                        />
                        <FormField
                          type={twitter.type}
                          label={twitter.label}
                          name={twitter.name}
                          value={values?.socialProfile?.twitter}
                          placeholder={twitter.placeholder}
                        />
                      </MDBox>
                    </Grid>
                    {/* SNAPCHAT */}
                    <Grid item xs={12} sm={6}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <SvgIcon
                          sx={{ width: '2em', height: '2em', mr: 1 }}
                          color={'primary'}
                        >
                          <SnapChat />
                        </SvgIcon>
                        <FormField
                          type={snapchat.type}
                          label={snapchat.label}
                          name={snapchat.name}
                          value={values?.socialProfile?.snapchat}
                          placeholder={snapchat.placeholder}
                        />
                      </MDBox>
                    </Grid>
                    {/* ONLY FANS */}
                    <Grid item xs={12} sm={6}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <SvgIcon sx={{ width: '2em', height: '1.6em', mr: 1 }}>
                          <OnlyFans />
                        </SvgIcon>
                        <FormField
                          type={onlyfans.type}
                          label={onlyfans.label}
                          name={onlyfans.name}
                          value={values?.socialProfile?.onlyfans}
                          placeholder={onlyfans.placeholder}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>

                <MDBox
                  my={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => setIsEdit(false)}
                  >
                    back
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </MDBox>
  );
};

export default MyProfileEdit;
