import TransactionListItem from './TransactionListItem';
import List from '@mui/material/List';
import { ListSubheader } from '@mui/material';
import MDBox from '../../../../components/MDBox';
import NoTransactionListItem from './NoTransactionListItem';

function TransactionsList({ transactions, title, short = true }) {
  return (
    <List subheader={<ListSubheader>{title}</ListSubheader>}>
      <MDBox
        maxHeight={short ? 215 : 530}
        sx={{ overflow: 'scroll', pr: short ? 1.25 : 2 }}
      >
        {!transactions.length ? (
          <NoTransactionListItem />
        ) : (
          transactions.map((item, idx) => (
            <TransactionListItem key={idx} invoice={item} />
          ))
        )}
      </MDBox>
    </List>
  );
}

export default TransactionsList;
