import MDBox from '../../../../components/MDBox';
import Checkbox from '@mui/material/Checkbox';
import MDTypography from '../../../../components/MDTypography';

const AgeAndBannersCheckbox = ({ checked, action }) => {
  return (
    <MDBox display="flex" alignItems="center" justifyContent="center">
      <Checkbox
        disableRipple
        type="checkbox"
        checked={checked}
        onChange={action}
      />
      <MDTypography
        component="a"
        href="#"
        variant="button"
        color="primary"
        textGradient
      >
        &nbsp;&nbsp;I&apos;m over 18 years old and the banners and/or links used
        in this marketing campaign belong to me.
      </MDTypography>
    </MDBox>
  );
};

export default AgeAndBannersCheckbox;
