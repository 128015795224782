import React, { useState } from 'react';
import { Card, CardActions } from '@mui/material';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';
import MDButton from '../../../../components/MDButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import MDInput from '../../../../components/MDInput';
import MDButtonNoFocus from '../../../../components/MDButtonNoFocus';
import useRedeemVoucher from '../../../../utils/hooks/use-redeemVoucher';
import { toast } from 'react-toastify';

function RedeemVoucherCard() {
  const [showRedeem, setShowRedeem] = useState(false);
  const [redeemCode, setRedeemCode] = useState('');
  const toggleRedeem = () => {
    setShowRedeem(!showRedeem);
    if (!showRedeem) {
      setRedeemCode('');
    }
  };

  const { redeemVoucher } = useRedeemVoucher();

  const handleApply = () => {
    const res = redeemVoucher({
      variables: { code: redeemCode },
    });
    toast.promise(res, {
      pending: 'Loading',
      success: {
        render({ data }) {
          return data.data.redeemVoucher.message;
        },
      },
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
    setRedeemCode('');
  };
  return (
    <Card sx={{ boxShadow: 4 }}>
      <MDBox
        py={1}
        px={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MDTypography variant="h6" fontWeight="medium">
          Redeem Voucher
        </MDTypography>
        <MDButton variant={'text'} iconOnly onClick={toggleRedeem}>
          {showRedeem ? (
            <ExpandLessIcon
              color="primary"
              sx={{ width: '2rem', height: '2rem' }}
            />
          ) : (
            <ExpandMoreIcon
              color="primary"
              sx={{ width: '2rem', height: '2rem' }}
            />
          )}
        </MDButton>
      </MDBox>
      {showRedeem && (
        <CardActions>
          <Grid container rowSpacing={1} justifyContent={'center'}>
            <Grid item xs={11} mt={-2} pl={0.5}>
              <MDTypography variant="caption">Enter voucher code</MDTypography>
            </Grid>
            <Grid item xs={11}>
              <MDInput
                disabled={!showRedeem}
                label={'Redeem code'}
                fullWidth
                value={redeemCode}
                onChange={(e) => setRedeemCode(e.target.value)}
              />
            </Grid>
            <Grid
              my={1.5}
              item
              container
              xs={11}
              justifyContent={'space-around'}
            >
              <Grid item>
                <MDButtonNoFocus
                  disableRipple
                  variant={'outlined'}
                  color={'primary'}
                  size={'small'}
                  onClick={handleApply}
                >
                  apply
                </MDButtonNoFocus>
              </Grid>
              <Grid item>
                <MDButtonNoFocus
                  disableRipple
                  variant={'outlined'}
                  color={'primary'}
                  size={'small'}
                  onClick={() => {
                    setRedeemCode('');
                    setShowRedeem(false);
                  }}
                >
                  cancel
                </MDButtonNoFocus>
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

export default RedeemVoucherCard;
