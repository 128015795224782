import Grid from '@mui/material/Grid';
import MDTypography from 'components/MDTypography';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export function SummaryLine(props) {
  const { number, text } = props;
  return (
    <Grid container>
      <Grid item xs={1.4} mr={0.5}>
        <MDTypography
          color="success"
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
            textAlign: 'right',
          }}
        >
          {number}
        </MDTypography>
      </Grid>
      <Grid item>
        <MDTypography
          sx={{
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          websites
        </MDTypography>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems="center">
          <ChevronRightIcon fontSize="medium" color="secondary" />
          <MDTypography
            color="secondary"
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              letterSpacing: 0.25,
            }}
          >
            {text}
          </MDTypography>
        </Stack>
      </Grid>
    </Grid>
  );
}
