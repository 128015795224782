// @mui material components
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/DashboardLayout';
import Footer from 'components/Footer';

// Wizard page components
import Audience from 'pages/ad_wizard/components/Audience';
import Banners from 'pages/ad_wizard/components/Banners';
import Confirm from 'pages/ad_wizard/components/Confirm';

// Images
import { useAdWizardContext } from './context';
import { next4Hours, nextHour } from '../../utils/dateUtils/dateUtils';
import Countdown from 'react-countdown';
import SessionExpired from './components/SessionExpired/SessionExpired';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

function getSteps() {
  return ['Audience', 'Banners', 'Confirm'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case -1:
      return <SessionExpired />;
    case 0:
      return <Audience />;
    case 1:
      return <Banners />;
    case 2:
      return <Confirm />;
    default:
      return null;
  }
}

function getStepTitle(stepIndex) {
  switch (stepIndex) {
    case -1:
      return 'Session Expired';
    case 0:
      return 'Create Ad';
    case 1:
      return 'Select available banners';
    case 2:
      return 'Confirm';
    default:
      return null;
  }
}

const AdWizard = () => {
  const { isSessionExpired, activeStep } = useAdWizardContext();

  const steps = getSteps();

  return (
    <DashboardLayout>
      <MDBox
        maxWidth={950}
        mx="auto"
        mt={3}
        minHeight={{ sm: '82vh', lg: '90vh' }}
      >
        <Stack justifyContent="center">
          <MDTypography
            mb={6}
            variant="h3"
            fontWeight="regular"
            align={'center'}
          >
            {getStepTitle(activeStep)}
          </MDTypography>
          <Card>
            <MDBox mt={-3} mx={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>
            <MDBox mx={2} mt={1} textAlign="center">
              {!isSessionExpired && <SessionCountdown />}
            </MDBox>
            <MDBox p={2}>
              {getStepContent(activeStep)}
              {!isSessionExpired && <ButtonsArea />}
            </MDBox>
          </Card>
        </Stack>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdWizard;

function LowFundsButton() {
  const navigate = useNavigate();
  return (
    <MDButton
      disableRipple
      variant="outlined"
      color="primary"
      size="small"
      sx={{ px: { xs: 1, md: 2 }, ml: 1 }}
      onClick={() => navigate('/pages/pricing')}
    >
      low funds
    </MDButton>
  );
}

function TokensBalance() {
  const { totalTokens, tokens } = useAdWizardContext();
  return (
    <MDTypography
      variant="h4"
      fontWeight="bold"
      order={{ xs: '-1', md: '0' }}
      minWidth={{ xs: '100%', md: 'unset' }}
      justifyContent="center"
      marginBottom={{ xs: '10px', md: '0' }}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      Total:
      <span
        style={{
          color: totalTokens > tokens ? '#e91e63' : '#6EB88B',
          margin: '0 5px',
          fontWeight: 'bold',
          fontSize: '24px',
        }}
      >
        {totalTokens}
      </span>
      Tokens
      {totalTokens > tokens && <LowFundsButton />}
    </MDTypography>
  );
}

function SessionCountdown() {
  const { sessionStart, setBannersSetted, setIsSessionExpired, setActiveStep } =
    useAdWizardContext();
  return (
    <MDTypography variant="subtitle2" fontWeight="bold" color="text">
      <Countdown
        date={sessionStart}
        renderer={({ minutes, seconds, completed }) => {
          return `Your session will expire in: ${minutes} minute${
            minutes === 1 ? '' : 's'
          } ${seconds} second${seconds === 1 ? '' : 's'}`;
        }}
        onComplete={() => {
          setBannersSetted([]);
          setIsSessionExpired(true);
          setActiveStep(-1);
        }}
      />
    </MDTypography>
  );
}

function ButtonsArea() {
  const {
    activeStep,
    setActiveStep,
    lockButton,
    setStart,
    setEnd,
    handleSubmitCreateCampaign,
  } = useAdWizardContext();

  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    if (activeStep === 1) {
      setStart(nextHour());
      setEnd(next4Hours());
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleSubmit = () => {
    handleSubmitCreateCampaign();
  };

  return (
    <MDBox
      mt={3}
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexWrap={activeStep === 1 ? { xs: 'wrap', md: 'nowrap' } : {}}
    >
      {activeStep === 0 ? (
        <MDBox />
      ) : (
        <MDButton variant="gradient" color="light" onClick={handleBack}>
          back
        </MDButton>
      )}
      {activeStep === 1 && <TokensBalance />}
      <MDButton
        disabled={lockButton(activeStep)}
        variant="gradient"
        color="dark"
        onClick={!isLastStep ? handleNext : handleSubmit}
      >
        {isLastStep ? 'confirm order' : 'next'}
      </MDButton>
    </MDBox>
  );
}
