import { useCreateCampaign } from 'utils/mutations/mutations';
import { useProfile } from 'pages/user_profile/userProfileContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { nextHour } from '../../utils/dateUtils/dateUtils';
import useAllPartners from '../../utils/hooks/use-allPartners';

const AdWizardContext = React.createContext();

const sessionTimeOut = 600000; //msec

export const AdWizardProvider = ({ children }) => {
  const navigate = useNavigate();
  const { createCampaign } = useCreateCampaign();
  const {
    user: { tokens, banners },
  } = useProfile();
  const { data: { allPartners = [] } = {} } = useAllPartners();
  const [totalTokens, setTotalTokens] = useState(0);
  const [selectedCat, setSelectedCat] = useState([]);
  const [selectedSub, setSelectedSub] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ageAndBannersConfirm, setAgeAndBannersConfirm] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [sessionStart, setSessionStart] = useState(getSessionStart());
  const [activeStep, setActiveStep] = useState(0);

  const [bannersSetted, setBannersSetted] = useState([]);
  const [spotPrices, setSpotPrices] = useState([]);

  useEffect(() => {
    const updatedTotalTokens = bannersSetted.reduce(
      (updatedTotalTokens, currentBanner) => {
        const spotPrice = spotPrices.find(
          (spot) => spot.bannerType === currentBanner.type
        ).tokenPrice;
        updatedTotalTokens += currentBanner.nrOfSites * spotPrice;
        return updatedTotalTokens;
      },
      0
    );
    setTotalTokens(updatedTotalTokens);
  }, [bannersSetted]);

  function getSessionStart() {
    return nextHour() - Date.now() > sessionTimeOut
      ? Date.now() + sessionTimeOut
      : nextHour();
  }

  const toggleAgeAndBannersConfirm = () => {
    setAgeAndBannersConfirm(!ageAndBannersConfirm);
  };

  const setBanner = (type, newBanner) => {
    let updatedBanners = [...bannersSetted];
    if (newBanner.nrOfSites > 0) {
      if (bannersSetted.find((banner) => banner.type === type)) {
        updatedBanners = bannersSetted.map((banner) =>
          banner.type === type ? { ...newBanner, type: type } : banner
        );
      } else {
        updatedBanners.push({ type, ...newBanner });
      }
    } else {
      updatedBanners = bannersSetted.filter((banner) => banner.type !== type);
    }
    setBannersSetted(updatedBanners);
  };

  const removeBanner = (type) => {
    const updatedBanners = bannersSetted.filter(
      (banner) => banner.type !== type
    );
    setBannersSetted(updatedBanners);
  };

  const lockButton = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          !selectedCat.length || !selectedChannels.length || isSessionExpired
        );
      case 1:
        return (
          bannersSetted.length === 0 ||
          isSessionExpired ||
          tokens - totalTokens < 0
        );
      case 2:
        return (
          tokens - totalTokens < 0 || !ageAndBannersConfirm || isSessionExpired
        );
      default:
        return true;
    }
  };

  const handleSubmitCreateCampaign = () => {
    const updatedAtVal = new Date();
    const duration = { startDate, endDate };
    const numberOfSites = bannersSetted.reduce(
      (previousValue, currentValue) =>
        (previousValue += currentValue.nrOfSites),
      0
    );
    const res = createCampaign({
      variables: {
        input: {
          banners: bannersSetted,
          tokens: totalTokens,
          preferredChannels: selectedChannels,
          subCategory: selectedSub,
          category: selectedCat,
          nrOfSites: numberOfSites,
          updatedAt: updatedAtVal,
          duration,
        },
      },
    });

    toast.promise(res, {
      pending: 'In progress',
      success: {
        render() {
          navigate('/pages/user_campaigns', { replace: true });
          return 'Successfull';
        },
      },
      error: {
        render({ data }) {
          navigate('/create-ad', { replace: true });
          return data.message;
        },
      },
    });
  };

  return (
    <AdWizardContext.Provider
      value={{
        selectedCat,
        setSelectedCat,
        selectedSub,
        setSelectedSub,
        selectedChannels,
        setSelectedChannels,
        totalTokens,
        setTotalTokens,
        bannersSetted,
        setBannersSetted,
        setBanner,
        removeBanner,
        lockButton,
        handleSubmitCreateCampaign,
        start: startDate,
        setStart: setStartDate,
        end: endDate,
        setEnd: setEndDate,
        ageAndBannersConfirm,
        toggleAgeAndBannersConfirm,
        isSessionExpired,
        setIsSessionExpired,
        sessionStart,
        setSessionStart,
        activeStep,
        setActiveStep,
        getSessionStart,
        tokens,
        banners: banners || [],
        allPartners,
        setSpotPrices,
      }}
    >
      {children}
    </AdWizardContext.Provider>
  );
};

export const useAdWizardContext = () => {
  return useContext(AdWizardContext);
};
