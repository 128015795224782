const today = new Date().setHours(0, 0, 0, 0);

const yesterday = new Date(
  new Date().setDate(new Date().getDate() - 1)
).setHours(0, 0, 0, 0);

export const isFromToday = (date) => new Date(date) >= new Date(today);

export const wasYesterday = (date) =>
  new Date(date) >= new Date(yesterday) && new Date(date) < new Date(today);

export const isOlderThanYesterday = (date) =>
  new Date(date) < new Date(yesterday);

export const dateToDisplay = (date) =>
  `${new Date(date).toLocaleDateString()} - ${new Date(
    date
  ).toLocaleTimeString()}`;
