/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { USER_WIZARD } from '../mutations/user-wizard';

/**
 * Internal dependencies
 */

export default function useUserWizard() {
  const [userWizard, { loading }] = useMutation(USER_WIZARD);

  return { userWizard, loading };
}
