/* eslint-disable no-unused-vars */
import FormField from 'components/FormField';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

import React, { useState } from 'react';

const PasswordField = ({ formData }) => {
  const { formField, values, touched, errors } = formData;
  const { password } = formField;
  const { password: passwordV } = values;
  const [showPass, setShowPass] = useState(false);

  return (
    <FormField
      type={showPass ? 'text' : password.type}
      label={password.label}
      name={password.name}
      value={passwordV}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              edge="end"
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
