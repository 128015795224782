/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL } from '../mutations/verifyEmail';

/**
 * Internal dependencies
 */

export default function useVerifyEmail() {
  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL);

  return { verifyEmail, loading };
}
