/* eslint-disable no-unused-vars */
import React from 'react';
import MDBox from '../../components/MDBox';
import Card from '@mui/material/Card';
import MDTypography from '../../components/MDTypography';
import { ThreeCircles } from 'react-loader-spinner';
import PageLayout from '../../components/PageLayout';
import Grid from '@mui/material/Grid';
import Footer from '../../components/Footer';
import bgImg from '../../assets/images/bg-xstream.png';

const Loading = () => {
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImg}) `,
          // backgroundColor: '#060022 ',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: { xs: '110%', sm: '102%' },
          zIndex: -1,
        }}
      />
      <MDBox
        px={1}
        width="100%"
        mx="auto"
        sx={{ height: { xs: '95vh', md: '90vh', lg: '95vh' } }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="primary"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  XStream Traffic
                </MDTypography>
              </MDBox>
              <MDBox
                pt={4}
                pb={3}
                px={3}
                display={'flex'}
                justifyContent={'center'}
              >
                <ThreeCircles
                  color="#e91e63"
                  innerCircleColor="#7b809a"
                  width={55}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
};

export default Loading;
