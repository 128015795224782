import PropTypes from 'prop-types';
import DefaultPricingCard from 'components/DefaultPricingCard';
import { Stack } from '@mui/material';

const PricingCardsDisabled = ({ prices }) => {
  const currencyToTokens = (price) => price * 20;
  const calculateBonus = (price) => price * 2;
  const getBundleTitle = (idx) => {
    switch (idx) {
      case 0:
        return 'starter';
      case 1:
        return 'pro';
      case 2:
        return 'elite';
      default:
        return 'regular';
    }
  };

  return (
    <Stack
      mt={8}
      mb={{ xs: 3, sm: 4 }}
      mx={{ xs: 3, sm: 4 }}
      spacing={{ xs: 3, sm: 1.5 }}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {prices.map((price, idx) => (
        <DefaultPricingCard
          key={`${price}-${idx}`}
          color={'white'}
          badge={{
            color: 'light',
            label: `${getBundleTitle(idx)}`,
          }}
          price={{ currency: '$', value: price }}
          specifications={[
            { label: `${currencyToTokens(price)} tokens`, includes: true },
            {
              label: `Bundle bonus ${calculateBonus(price)} tokens`,
              includes: true,
            },
          ]}
          actions={[
            {
              key: 1,
              type: 'disabled',
              handler: () => {},
              color: 'dark',
              label: 'Buy',
            },
          ]}
          shadow={false}
        />
      ))}
    </Stack>
  );
};

// Typechecking props for the PricingCards
PricingCardsDisabled.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCardsDisabled;
