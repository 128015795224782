import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { ListItem, ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import colors from '../../../../assets/theme/base/colors';
import { dateToDisplay } from '../../utils/dateUtils';

const ShortId = require('id-shorter');
const idShortener = ShortId();

function InvoiceListItem({ invoice }) {
  const {
    _id,
    amount,
    currency,
    dateCreated,
    details,
    fileUrl,
    invoiceNumber,
  } = invoice;

  return (
    <ListItem
      secondaryAction={`${amount} ${currency}`}
      {...moneyToDisplayStyle}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: colors.white.main }}>
          <ReceiptLongIcon color="text" fontSize="medium" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`Invoice #${idShortener.encode(_id)}`}
        primaryTypographyProps={primaryTypographyProps}
        secondary={dateToDisplay(dateCreated)}
        secondaryTypographyProps={secondaryTypographyProps}
      />
    </ListItem>
  );
}

const moneyToDisplayStyle = {
  sx: {
    '& .MuiListItemSecondaryAction-root': {
      color: colors.secondary.main,
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
};

const primaryTypographyProps = {
  color: colors.secondary.main,
  fontSize: 14,
  fontWeight: 'bold',
};

const secondaryTypographyProps = {
  color: colors.secondary.main,
  fontSize: 12,
};

export default InvoiceListItem;
