/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const ALL_CAMPAIGNS_USER = gql`
  query AllCampaignsUser {
    allCampaignsUser {
      _id
      updatedAt
      category
      subCategory
      preferredChannels
      nrOfSites
      serviceType
      duration {
        startDate
        endDate
      }
      userId
      tokens
      banners {
        siteId
        siteName
        siteDomain
        type
        imageUrl
        redirectUrl
      }
    }
  }
`;
