/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { Switch } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Wizard application components
import { useEffect, useState } from 'react';
import { sites } from '../../../../utils/data/Fill-InAndOtherData';

function WorkingSites({ formData }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { workingSites } = formField;
  const { workingSites: workingSitesV } = values;
  const [otherDisabled, setOtherDisabled] = useState(true);

  const toggleChecked = (e) => {
    const name = e.target.name;
    let wSites;
    if (workingSitesV.includes(name)) {
      wSites = workingSitesV.filter((item) => item !== name);
    } else {
      wSites = workingSitesV;
      wSites.push(name);
    }
    setFieldValue('workingSites', wSites);
  };

  const updateOther = (e, newValue) => {
    let wSites = workingSitesV;
    wSites.shift();
    wSites.unshift(e.target.value);
    setFieldValue('workingSites', wSites);
  };

  useEffect(() => {
    let wSites = workingSitesV;
    if (otherDisabled) {
      wSites.shift();
    } else {
      wSites.unshift('');
    }
    setFieldValue('workingSites', wSites);
  }, [otherDisabled]);

  return (
    <MDBox>
      <MDBox width="80%" sx={{ mx: { xs: 2, sm: 4 } }} my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            What site do you work for?
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          Required
        </MDTypography>
      </MDBox>
      <MDBox
        mt={2}
        sx={{
          width: { xs: '100%', sm: '85%' },
          pl: { xs: 2, sm: 4 },
          pr: { xs: 2, sm: 4 },
        }}
      >
        <FormControl>
          <FormGroup>
            <Grid container>
              {sites.map((site) => {
                return (
                  <Grid item key={site.id} xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="warning"
                          name={site.name}
                          checked={workingSitesV.includes(site.name)}
                          onChange={toggleChecked}
                        />
                      }
                      label={
                        <MDTypography variant="button">
                          {site.label}
                        </MDTypography>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={5} sm={3}>
                <MDBox
                  sx={{
                    minHeight: '85px',
                    pt: 1.8,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setOtherDisabled(!otherDisabled)}
                        name="other"
                      />
                    }
                    label={<MDTypography variant="button">Other</MDTypography>}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={7} sm={6}>
                <MDBox sx={{ minHeight: '85px' }}>
                  {!otherDisabled && (
                    <TextField
                      sx={{ '& .MuiInputBase-input': { fontSize: '15px' } }}
                      color="primary"
                      size="small"
                      margin="normal"
                      name="other"
                      hiddenLabel
                      fullWidth
                      value={workingSitesV[0] || ''}
                      onChange={updateOther}
                      helperText={
                        workingSitesV[0]
                          ? ''
                          : 'Please provide value or disable.'
                      }
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}

export default WorkingSites;
