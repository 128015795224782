/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import ApolloClientProvider from 'utils/apollo-client/apollo-client-provider';
import { AuthProvider } from './authentication/provider';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from 'errboundary/FallBack';
import { version } from '../package.json';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from 'context';
import AuthContextFirebase from 'utils/firebase/AuthContext';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '46253891a3500279a85e48d2606b934f',
  plugins: [new BugsnagPluginReact()],
  appVersion: version,
});

const BgSngErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <BgSngErrorBoundary>
    <ErrorBoundary FallbackComponent={FallBack}>
      <AuthProvider>
        <ApolloClientProvider>
          <BrowserRouter>
            <MaterialUIControllerProvider>
              <AuthContextFirebase>
                <App />
              </AuthContextFirebase>
            </MaterialUIControllerProvider>
          </BrowserRouter>
        </ApolloClientProvider>
      </AuthProvider>
    </ErrorBoundary>
  </BgSngErrorBoundary>,
  document.getElementById('root')
);
export { avatarImg } from './utils/data/Fill-InAndOtherData';
