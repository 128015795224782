import React, { useContext, createContext, useState } from 'react';
import { useUserProfile } from 'utils/queries/queries';
import Loading from './loading';
const UserProfileContext = createContext({});

export const UserProfileProvider = ({ children }) => {
  const { data, loading, refetch: refetchUser } = useUserProfile();
  const [avatarURL, setAvatarURL] = useState(null);

  if (loading) {
    return <Loading />;
  }

  return (
    <UserProfileContext.Provider
      value={{
        user: data?.user || null,
        avatarURL,
        setAvatarURL,
        refetchUser,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
export const useProfile = () => useContext(UserProfileContext);
