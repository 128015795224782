/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { ALL_CAMPAIGNS_USER } from '../queries/allCampaignsUser';

export default function useAllCampaignsUser() {
  const { data, loading, error } = useQuery(ALL_CAMPAIGNS_USER, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}
