/* eslint-disable no-unused-vars */
import { useState } from 'react';
import Footer from 'components/Footer';
import PricingCards from 'components/PricingCards';
import DashboardLayout from 'components/DashboardLayout';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Stack } from '@mui/material';
import ShieldMini from '../../components/ShieldMini/ShieldMini';
import PricingCardsDisabled from '../../components/PricingCards/PricingCardsDisabled';
import PaxumPricingCards from '../../components/PaxumPricingCards';
import Divider from '@mui/material/Divider';

function PricingPage() {
  const [modelsBundles, setModelsBundles] = useState(['15', '30', '90']);
  const [studioBundles, setStudioBundles] = useState(['50', '150', '350']);

  return (
    <DashboardLayout>
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        mx="auto"
        minHeight={{ lg: '80vh' }}
      >
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mt={-3}
                mb={1.5}
                mx={4}
                bgColor="primary"
                borderRadius="lg"
                minHeight="80px"
                textAlign="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MDTypography variant="h3" color="light" fontWeight="regular">
                  Pricing
                </MDTypography>
              </MDBox>

              <MDBox p={4}>
                <Stack spacing={6}>
                  <Card sx={{ boxShadow: 0.25 }}>
                    <ShieldMini title={'Models Bundles'} tColor={'light'} />
                    <PricingCards prices={modelsBundles} />
                  </Card>
                  <Card sx={{ boxShadow: 0.25 }}>
                    <ShieldMini
                      title={'Studio Bundles - Coming soon!'}
                      tColor={'light'}
                    />
                    <PricingCardsDisabled prices={studioBundles} />
                  </Card>
                </Stack>

                <MDBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => window.history.back()}
                  >
                    back
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PricingPage;
