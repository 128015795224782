import React from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Chip, SvgIcon } from '@mui/material';
import MDButton from 'components/MDButton';
import { ReactComponent as OnlyFans } from '../../utils/logos/Onlyfans-icon-rounded.svg';
import { ReactComponent as SnapChat } from '../../utils/logos/snapchat-square-2.svg';
import { ReactComponent as Instagram } from '../../utils/logos/instagram-alt.svg';
import Grid from '@mui/material/Grid';

const lod_str = require('lodash/string');

const MyProfile = ({ setIsEdit, userData }) => {
  const {
    username,
    profile: { gender, birthDate },
    blockCountries,
    workingSites,
    socialProfile: { instagram, twitter, snapchat, onlyfans },
  } = userData;

  return (
    <MDBox mx={{ xs: 3, md: 6 }} mt={4} mb={3}>
      <Grid container rowSpacing={{ xs: 1.3, md: 2 }}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              Username
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography variant="subtitle2" color="secondary">
              {username ?? 'Undefined'}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              I'm
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography variant="subtitle2" color="secondary">
              {lod_str.capitalize(gender ?? 'Undefined')}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              Birth Date
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography variant="subtitle2" color="secondary">
              {birthDate ? birthDate.substring(0, 10) : 'Undefined'}
            </MDTypography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              Block Countries
            </MDTypography>
          </Grid>
          <Grid item container xs={10} columnSpacing={0.5}>
            {!blockCountries
              ? 'Undefined'
              : blockCountries.map((country) => {
                  return (
                    <Grid item key={country}>
                      <Chip label={country} color={'secondary'} size="small" />
                    </Grid>
                  );
                })}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              Sites
            </MDTypography>
          </Grid>
          <Grid item container xs={10} columnSpacing={0.5}>
            {!workingSites
              ? 'Undefined'
              : workingSites.map((site) => {
                  return (
                    <Grid item key={site}>
                      <Chip label={site} color={'secondary'} size={'small'} />
                    </Grid>
                  );
                })}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2} mr={{ md: 4 }}>
            <MDTypography variant="h6" color="secondary" fontWeight="bold">
              Social Media Links
            </MDTypography>
          </Grid>
          <Grid item container xs={12} md={9}>
            {instagram && (
              <Grid item container xs={12} md={6}>
                <Grid item xs={3} md={2}>
                  <SvgIcon
                    sx={{ width: '2em', height: '2em' }}
                    color={'primary'}
                  >
                    <Instagram />
                  </SvgIcon>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {instagram}
                  </MDTypography>
                </Grid>
              </Grid>
            )}
            {twitter && (
              <Grid item container xs={12} md={6}>
                <Grid item xs={3} md={2}>
                  <TwitterIcon
                    sx={{ color: '#E91F63', width: '2em', height: '2em' }}
                  />
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {twitter}
                  </MDTypography>
                </Grid>
              </Grid>
            )}
            {snapchat && (
              <Grid item container xs={12} md={6}>
                <Grid item xs={3} md={2}>
                  <SvgIcon
                    sx={{ width: '2em', height: '2em' }}
                    color={'primary'}
                  >
                    <SnapChat />
                  </SvgIcon>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {snapchat}
                  </MDTypography>
                </Grid>
              </Grid>
            )}
            {onlyfans && (
              <Grid item container xs={12} md={6}>
                <Grid item xs={3} md={2}>
                  <SvgIcon sx={{ width: '2em', height: '1.6em' }}>
                    <OnlyFans />
                  </SvgIcon>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {onlyfans}
                  </MDTypography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center" my={2}>
          <MDButton
            color="primary"
            size="large"
            onClick={() => setIsEdit(true)}
          >
            edit profile
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default MyProfile;
