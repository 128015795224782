/* eslint-disable import/no-anonymous-default-export */
import * as Yup from 'yup';
import checkout from './form';
import { adultLimitDate, getArrDate } from 'utils/dateUtils/dateUtils';
import apolloClient from 'utils/apollo-client/apollo-client';
import { CHECK_USERNAME } from 'utils/queries/checkUsername';
import { ALL_COUNTRIES } from 'utils/queries/allCountries';

const {
  formField: {
    username,
    gender,
    birthDate,
    avatar,
    blockCountries,
    workingSites,
    instagram,
    twitter,
    snapchat,
    onlyfans,
  },
} = checkout;

export default [
  Yup.object().shape({
    [username.name]: Yup.string()
      .required('Required field')
      .min(3, 'At least 3 characters')
      .test('check-user', 'Username not available!', async (value) => {
        if (value) {
          const response = await apolloClient(
            JSON.parse(window.localStorage.getItem('sessionToken'))
          ).query({
            query: CHECK_USERNAME,
            variables: { username: value },
          });
          return !response.data.checkUsername.found;
        }
      }),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [birthDate.name]: Yup.string().test((value) => {
      const [DD, MM, YYYY] = getArrDate(value);
      const currentBirthDate = new Date(YYYY, MM, DD);
      return currentBirthDate <= adultLimitDate;
    }),
    [avatar.name]: Yup.string().required(avatar.errorMsg),
    [blockCountries.name]: Yup.array().test(
      'blockCountries',
      'Invalid option!',
      async (value) => {
        const response = await apolloClient(
          JSON.parse(window.localStorage.getItem('sessionToken'))
        ).query({
          query: ALL_COUNTRIES,
        });
        if (value.length === 0) return true;
        return value.every((country) => {
          return response.data.allCountries
            .map((country) => country.label)
            .includes(country);
        });
      }
    ),
  }),
  Yup.object().shape({
    [instagram.name]: Yup.string(),
    [twitter.name]: Yup.string(),
    [snapchat.name]: Yup.string(),
    [onlyfans.name]: Yup.string(),
  }),
  Yup.object().shape({
    [workingSites.name]: Yup.array().test((value) => {
      return value.length > 0 && !!value[0];
    }),
  }),
];
