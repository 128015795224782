/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import { adultAge } from '../../../utils/dateUtils/dateUtils';

/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  formId: 'new-user-form',
  formField: {
    username: {
      name: 'username',
      label: 'Username',
      type: 'text',
      errorMsg: 'Username is required.',
    },
    gender: {
      name: 'gender',
      label: 'Gender',
      type: 'text',
      errorMsg: 'Gender is required.',
    },

    birthDate: {
      name: 'birthDate',
      label: 'Birth Date',
      type: 'text',
    },

    day: {
      name: 'day',
      label: 'Day',
      type: 'text',
      errorMsg: 'Day is required.',
    },

    month: {
      name: 'month',
      label: 'Month',
      type: 'text',
      errorMsg: 'Day is required.',
    },

    year: {
      name: 'year',
      label: 'Year',
      type: 'text',
      errorMsg: 'Year is required.',
    },
    avatar: {
      name: 'avatar',
      label: 'Avatar',
    },
    blockCountries: {
      name: 'blockCountries',
      label: 'Block Countries',
      type: 'text',
      errorMsg: 'Block countries is required.',
    },
    workingSites: {
      name: 'workingSites',
      label: 'Sites',
      type: 'text',
      errorMsg: 'Sites is required.',
    },
    instagram: {
      name: 'instagram',
      label: 'Instagram',
      type: 'text',
      errorMsg: 'Instagram is required.',
    },
    twitter: {
      name: 'twitter',
      label: 'Twitter',
      type: 'text',
      errorMsg: 'Twitter is required.',
    },
    snapchat: {
      name: 'snapchat',
      label: 'SnapChat',
      type: 'text',
      errorMsg: 'SnapChat is required.',
    },
    onlyfans: {
      name: 'onlyfans',
      label: 'Only Fans',
      type: 'text',
      errorMsg: 'onlyfans is required.',
    },
  },
};
