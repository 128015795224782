import MDTypography from '../../../components/MDTypography';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import {
  signInWithFacebook,
  signInWithGoogle,
  signInWithTwitter,
} from 'utils/firebase/config';
import { useCheckEmail } from 'utils/queries/queries';
import { toast } from 'react-toastify';
import useUserLogIn from 'utils/hooks/use-userLogIn';
import useAuthContext from 'authentication/use-AuthContext';
import { useNavigate } from 'react-router-dom';
import ShieldMain from '../../../components/ShieldMain/ShieldMain';

const socialLoginFunction = async (
  socialFunction,
  triggerCheckEmail,
  loginUser,
  setSessionToken,
  navigate,
  socialProvider
) => {
  const socialFunctionCall = socialFunction();
  return await toast.promise(socialFunctionCall, {
    pending: 'In progress',
    success: {
      render({ data }) {
        console.log(data);
        if (!data) {
          return 'You closed auth pop-up!';
        }
        const {
          result: {
            user: { email, accessToken: idToken },
          },
          result,
        } = data;

        const socialFunctionCallData = triggerCheckEmail({
          variables: { email },
        });
        toast.promise(socialFunctionCallData, {
          pending: 'In progress',
          success: {
            render({ data }) {
              const {
                data: {
                  checkEmail: { email, found },
                },
              } = data;
              if (found) {
                const loginCall = loginUser({
                  variables: {
                    input: {
                      email: email,
                      password: '',
                      socialLogin: true,
                      socialProvider,
                      idToken,
                    },
                  },
                });
                toast.promise(loginCall, {
                  pending: 'In progress',
                  success: {
                    render({ data }) {
                      const {
                        data: {
                          loginUser: { loginToken, registrationWizard },
                        },
                      } = data;
                      if (data) {
                        const token = loginToken;
                        if (token) {
                          setSessionToken(token);
                          if (registrationWizard) {
                            navigate('/pages/user_wallet', {
                              replace: true,
                            });
                          } else {
                            navigate('/user-wizard', { replace: true });
                          }
                        }
                        return 'Logged in successfully!';
                      } else {
                        return 'Try Again!';
                      }
                    },
                  },
                  error: 'Error!',
                });
                return 'We found your email!';
              } else {
                const {
                  user: { email, accessToken, displayName },
                } = result;
                navigate(`/sign-up`, {
                  state: {
                    socialRegister: true,
                    username: displayName,
                    email: email,
                    password: accessToken,
                  },
                  replace: true,
                });
                return "Email not found! Let's create an account";
              }
            },
          },
          error: 'Error!',
        });
        return 'Successful!';
      },
    },
    error: {
      render({ data }) {
        return data.message;
      },
    },
  });
};

const SocialSignIn = ({ title, description }) => {
  const { triggerCheckEmail } = useCheckEmail();
  const { loginUser } = useUserLogIn();
  const { setSessionToken } = useAuthContext();
  const navigate = useNavigate();

  const handleSocialLogin = async (type) => {
    switch (type) {
      case 'google':
        await socialLoginFunction(
          signInWithGoogle,
          triggerCheckEmail,
          loginUser,
          setSessionToken,
          navigate,
          'google'
        );
        break;
      case 'facebook':
        await socialLoginFunction(
          signInWithFacebook,
          triggerCheckEmail,
          loginUser,
          setSessionToken,
          navigate,
          'facebook'
        );
        break;
      case 'twitter':
        await socialLoginFunction(
          signInWithTwitter,
          triggerCheckEmail,
          loginUser,
          setSessionToken,
          navigate,
          'twitter'
        );
        break;
      default:
        break;
    }
  };

  return (
    <ShieldMain title={title} description={description}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ mt: 0.25, mb: 0.5 }}
      >
        <Grid item xs={2}>
          <MDTypography
            component={MuiLink}
            href="#"
            variant="body1"
            color="white"
            onClick={() => handleSocialLogin('facebook')}
          >
            <FacebookIcon color="inherit" />
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography
            component={MuiLink}
            href="#"
            variant="body1"
            color="white"
            onClick={() => handleSocialLogin('twitter')}
          >
            <TwitterIcon color="inherit" />
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography
            component={MuiLink}
            href="#"
            variant="body1"
            color="white"
            onClick={() => handleSocialLogin('google')}
          >
            <GoogleIcon color="inherit" />
          </MDTypography>
        </Grid>
      </Grid>
    </ShieldMain>
  );
};

export default SocialSignIn;
