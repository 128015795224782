import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ListItem, ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import colors from '../../../../assets/theme/base/colors';

function NoTransactionListItem() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: colors.white.main }}>
          <HighlightOffIcon color="dark" fontSize="medium" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={'No transactions.'}
        primaryTypographyProps={primaryTypographyProps}
      />
    </ListItem>
  );
}

const primaryTypographyProps = {
  color: colors.secondary.main,
  fontSize: 14,
  fontWeight: 'regular',
};

export default NoTransactionListItem;
