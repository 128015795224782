import { gql } from '@apollo/client';

export const REDEEM_VOUCHER = gql`
  mutation RedeemVoucher($code: String) {
    redeemVoucher(code: $code) {
      node {
        _id
        username
        tokens
      }
      message
    }
  }
`;
