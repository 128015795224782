import { useField } from 'formik';
import MDBox from '../../../components/MDBox';
import Checkbox from '@mui/material/Checkbox';
import MDTypography from '../../../components/MDTypography';

const AdultAgeCheckbox = ({ ...adultAgeConfirm }) => {
  const [field] = useField(adultAgeConfirm);
  return (
    <MDBox display="flex" alignItems="center" my={2}>
      <Checkbox disableRipple {...field} type="checkbox" sx={{ ml: -1.25 }} />
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="text"
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        I confirm&nbsp;
      </MDTypography>
      <MDTypography
        variant="button"
        fontWeight="bold"
        color="primary"
        textGradient
      >
        I have 18 years or more
      </MDTypography>
    </MDBox>
  );
};

export default AdultAgeCheckbox;
