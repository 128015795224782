export const categories = ['FEMALE', 'MALE', 'GAY', 'TRANS', 'COUPLE'];
export const subCat = [
  '18',
  '19',
  '18years',
  '3dxchat',
  '8inch',
  'abs',
  'ahegao',
  'alpha',
  'american',
  'anal',
  'analplug',
  'analtoy',
  'anime',
  'arab',
  'armpits',
  'asia',
  'asian',
  'asiantrans',
  'ass',
  'asshole',
  'assplay',
  'atm',
  'aussie',
  'australia',
  'australian',
  'babe',
  'bbc',
  'bbw',
  'bdsm',
  'bear',
  'beard',
  'beautiful',
  'beautifultits',
  'beauty',
  'belly',
  'bi',
  'biceps',
  'big',
  'bigass',
  'bigballs',
  'bigbelly',
  'bigboob',
  'bigboobies',
  'bigboobs',
  'bigboots',
  'bigbooty',
  'bigclit',
  'bigcock',
  'bigcocks',
  'bigdick',
  'bigdildo',
  'bigdildos',
  'biglips',
  'bigload',
  'bignipples',
  'bigtoys',
  'bigwoman',
  'bisexual',
  'bj',
  'black',
  'blond',
  'blonde',
  'blondie',
  'blowjob',
  'blueeeyes',
  'blueeyes',
  'bondage',
  'boobies',
  'boobs',
  'boy',
  'bra',
  'braces',
  'breastmilk',
  'briefs',
  'british',
  'brunette',
  'bubblebutt',
  'bulge',
  'bull',
  'bush',
  'butt',
  'buttplug',
  'c2c',
  'cam2cam',
  'cameltoe',
  'cashmaster',
  'cei',
  'chastity',
  'chat',
  'chill',
  'chillin',
  'chubby',
  'classy',
  'cock',
  'cockring',
  'college',
  'collegegirl',
  'colombia',
  'colombian',
  'colombiana',
  'conversation',
  'cosplay',
  'cougar',
  'couple',
  'couples',
  'coupleshow',
  'cowgirl',
  'crazyticket',
  'creampie',
  'creamy',
  'crossdresser',
  'cuckold',
  'cum',
  'cumface',
  'cumgoal',
  'cumhard',
  'cuminside',
  'cumming',
  'cumprivate',
  'cumshot',
  'cumshow',
  'cumshows',
  'cumslut',
  'curly',
  'curved',
  'curves',
  'curvy',
  'cut',
  'cute',
  'cuteass',
  'cuteface',
  'cutesmile',
  'cutie',
  'dadbod',
  'daddy',
  'daddysgirl',
  'dance',
  'dancer',
  'dancing',
  'deepthroat',
  'deutsch',
  'dick',
  'dildo',
  'dildoshow',
  'dilf',
  'dirty',
  'dirtygirl',
  'dirtytalk',
  'doggy',
  'doggystyle',
  'dom',
  'domi',
  'dominant',
  'domination',
  'doublepenetration',
  'dp',
  'dripping',
  'dutch',
  'ebony',
  'edge',
  'edging',
  'elegant',
  'english',
  'exhibitionist',
  'facial',
  'fat',
  'fatcock',
  'feet',
  'feetfetish',
  'femboy',
  'femdom',
  'fetish',
  'findom',
  'fingering',
  'fingers',
  'fit',
  'fitbody',
  'fitmodel',
  'fitness',
  'flash',
  'fleshlight',
  'flex',
  'flexible',
  'foot',
  'footfetish',
  'footjob',
  'foreskin',
  'fountainsquirt',
  'freeshow',
  'french',
  'fresh',
  'friendly',
  'ftm',
  'fuck',
  'fuckass',
  'fuckface',
  'fuckmachine',
  'fuckpussy',
  'fun',
  'funny',
  'gag',
  'game',
  'gamer',
  'gaming',
  'gape',
  'gay',
  'geek',
  'german',
  'germany',
  'gfe',
  'ginger',
  'girl',
  'girlfriend',
  'girlfriendmaterial',
  'girlnextdoor',
  'glasses',
  'goal',
  'goals',
  'goddess',
  'goth',
  'guy',
  'guys',
  'gym',
  'hair',
  'hairy',
  'hairyarmpits',
  'hairybush',
  'hairycock',
  'hairypussy',
  'handsome',
  'hard',
  'hardcock',
  'hd',
  'heels',
  'hello',
  'hentai',
  'hidden',
  'highheels',
  'hitachi',
  'horny',
  'hornycouple',
  'hot',
  'hotbody',
  'hotboy',
  'hugeballs',
  'hugecock',
  'humiliation',
  'hung',
  'hunk',
  'hush',
  'indian',
  'interactivetoy',
  'italian',
  'italy',
  'japanese',
  'jerkoff',
  'jock',
  'joi',
  'kawaii',
  'keno',
  'kinky',
  'korean',
  'latex',
  'latin',
  'latina',
  'latinas',
  'latino',
  'leather',
  'leggings',
  'legs',
  'lesbian',
  'lingerie',
  'longlegs',
  'longlips',
  'longtongue',
  'love',
  'lovely',
  'lovense',
  'lovensecontrol',
  'lovenseon',
  'lovenses',
  'lush',
  'lushcontrol',
  'maid',
  'married',
  'master',
  'masturbate',
  'masturbation',
  'matura',
  'mature',
  'max',
  'messy',
  'messydeepthroat',
  'milf',
  'milk',
  'milky',
  'miss',
  'mistress',
  'model',
  'mom',
  'mommy',
  'monstercock',
  'muscle',
  'muscles',
  'naked',
  'nasty',
  'natural',
  'naturalbody',
  'naturalboobs',
  'naturaltits',
  'nature',
  'naughty',
  'new',
  'newmodel',
  'nice',
  'nipples',
  'nonnude',
  'nonude',
  'nora',
  'nude',
  'nylon',
  'nylons',
  'obedient',
  'office',
  'ohmibod',
  'oil',
  'oilbodyshow',
  'oilshow',
  'openpussy',
  'orgasm',
  'outdoor',
  'outside',
  'panties',
  'pantyhose',
  'papi',
  'party',
  'password',
  'passwordshow',
  'pawg',
  'pegging',
  'perky',
  'petite',
  'piercing',
  'pinay',
  'pinaycock',
  'pink',
  'pinkpussy',
  'play',
  'playful',
  'pm',
  'polla',
  'pornstar',
  'precum',
  'pregnant',
  'pretty',
  'private',
  'privates',
  'privateshow',
  'prv',
  'puffy',
  'puffynipples',
  'pussy',
  'pussylicking',
  'pussyplay',
  'pvt',
  'pvtshows',
  'queen',
  'redhair',
  'redhead',
  'redlips',
  'roleplay',
  'romantic',
  'room',
  'round',
  'russian',
  'saliva',
  'schoolgirl',
  'secretary',
  'seduction',
  'selfsuck',
  'sensual',
  'sex',
  'sexy',
  'shaved',
  'shorthair',
  'show',
  'showcum',
  'shower',
  'showfeet',
  'shy',
  'single',
  'sissy',
  'skin',
  'skinny',
  'slave',
  'slim',
  'slimbody',
  'sloppy',
  'slut',
  'small',
  'smallboobs',
  'smallcock',
  'smalldick',
  'smallnipples',
  'smalltits',
  'smart',
  'smile',
  'smoke',
  'smoker',
  'smoking',
  'smooth',
  'snap4life',
  'socks',
  'soles',
  'southern',
  'spank',
  'sph',
  'spit',
  'squirt',
  'squirting',
  'squirtshow',
  'squirty',
  'stocking',
  'stockings',
  'stoner',
  'str8',
  'straight',
  'strapon',
  'strip',
  'striptease',
  'strong',
  'stud',
  'student',
  'sub',
  'submissive',
  'suck',
  'supermodel',
  'sweet',
  'switch',
  'sybian',
  'talk',
  'tall',
  'tattoo',
  'tattoos',
  'tease',
  'teen',
  'teengirl',
  'teens',
  'thicc',
  'thick',
  'thickcock',
  'thickdick',
  'thin',
  'threesome',
  'tight',
  'tightpussy',
  'tiny',
  'tip',
  'tipmenu',
  'tits',
  'toes',
  'tokenkeno',
  'tomboy',
  'top',
  'topless',
  'torso',
  'toy',
  'toys',
  'trans',
  'transgender',
  'transgirl',
  'trio',
  'ts',
  'turkish',
  'twerk',
  'twerking',
  'twink',
  'ukraine',
  'ukrainian',
  'uncut',
  'usa',
  'vibe',
  'vibration',
  'voyeur',
  'welcome',
  'wet',
  'wetshirt',
  'wheel',
  'whitecock',
  'wifematerial',
  'wild',
  'women',
  'yoga',
  'young',
  'youngbeauty',
];
