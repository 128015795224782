// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Wizard application components
// Images
import { SummaryCard } from './summaryCard';
import { TotalCostCard } from './totalCostCard';
import { DurationCard } from './durationCard';
import AgeAndBannersCheckbox from './AgeAndBannersCheckbox';
import { useAdWizardContext } from '../../context';
const columnsNo = 19; //odd number

function Confirm() {
  const { ageAndBannersConfirm, toggleAgeAndBannersConfirm } =
    useAdWizardContext();
  return (
    <MDBox pt={4}>
      <Grid
        container
        columns={19}
        rowSpacing={{ xs: 4, sm: 8 }}
        justifyContent="space-around"
      >
        <Grid item xs={columnsNo} sm={(columnsNo - 1) / 2}>
          <SummaryCard />
        </Grid>
        <Grid item xs={columnsNo} sm={(columnsNo - 1) / 2}>
          <TotalCostCard />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={columnsNo}
          sm={(columnsNo - 1) / 2}
        >
          <Grid item xs>
            <DurationCard />
          </Grid>
        </Grid>
        <Grid item container xs={columnsNo}>
          <Grid item xs>
            <AgeAndBannersCheckbox
              checked={ageAndBannersConfirm}
              action={toggleAgeAndBannersConfirm}
            />
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Confirm;
