/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'components/DashboardLayout';
import Footer from 'components/Footer';
import MDAvatar from 'components/MDAvatar';
import MyProfile from './MyProfile';
import MyProfileEdit from './MyProfileEdit';
import { Icon, IconButton } from '@mui/material';
import ModalAvatar from './ModalAvatar';
import { useProfile } from './userProfileContext';

function AdWizard() {
  const [isEdit, setIsEdit] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const { user: userData, avatarURL, setAvatarURL } = useProfile();

  useEffect(() => {
    if (userData.profile?.avatar) {
      setAvatarURL(userData.profile.avatar);
    }
  }, []);

  return (
    <DashboardLayout>
      <ModalAvatar modalShow={modalShow} setModalShow={setModalShow} />
      <MDBox maxWidth={950} minHeight={{ md: '70vh', lg: '90vh' }} mx="auto">
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="regular">
                  Profile Page
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper
                  sx={{
                    maxWidth: { xs: '95%', md: '40%' },
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                >
                  <MDBox position="relative">
                    <MDAvatar
                      src={avatarURL}
                      alt="profile picture"
                      size="xxl"
                      variant="rounded"
                      sx={{ borderRadius: '50%' }}
                    />
                    {isEdit && (
                      <IconButton
                        color="white"
                        onClick={() => setModalShow(true)}
                        sx={{
                          position: 'absolute',
                          right: '0',
                          bottom: '0',
                          bgcolor: '#000',
                        }}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </IconButton>
                    )}
                  </MDBox>
                </Stepper>
              </MDBox>
              {!isEdit ? (
                <MyProfile setIsEdit={setIsEdit} userData={userData} />
              ) : (
                <MyProfileEdit setIsEdit={setIsEdit} userData={userData} />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdWizard;
