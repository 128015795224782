import React from 'react';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';
import MDButton from '../../../../components/MDButton';
import { useAdWizardContext } from '../../context';
import { Stack } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

const SessionExpired = () => {
  const {
    setActiveStep,
    setIsSessionExpired,
    setSessionStart,
    getSessionStart,
  } = useAdWizardContext();
  return (
    <MDBox
      maxWidth="350px"
      minHeight={{ xs: '380px', md: '500px' }}
      mx="auto"
      display="flex"
      justifyContent="center"
      textAlign="center"
    >
      <Stack alignItems="center" justifyContent="center" spacing={6}>
        <MDTypography variant="h4" color="primary">
          Your session has expired!
        </MDTypography>
        <MDTypography variant="h4" color="primary">
          Press button to restart!
        </MDTypography>
        <MDButton
          color="primary"
          sx={{ width: 80, height: 80, fontSize: 50 }}
          onClick={() => {
            setActiveStep(0);
            setIsSessionExpired(false);
            setSessionStart(getSessionStart());
          }}
        >
          <CachedIcon />
        </MDButton>
      </Stack>
    </MDBox>
  );
};

export default SessionExpired;
