/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, IconButton, Stack, useMediaQuery } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import React, { useEffect, useState } from 'react';
import { useAdWizardContext } from 'pages/ad_wizard/context';
import SitesControl from './SitesControl';
import SitesError from './SitesError';
import ModalEditBanner from './ModalEditBanner';
import MDButton from '../../../../components/MDButton';

const BannerTemplate = ({
  type,
  imageUrl,
  redirectUrl,
  availableSpots,
  allAvailableSpots,
}) => {
  const { bannersSetted, setBanner, setTotalTokens, totalTokens } =
    useAdWizardContext();
  const isMobile = useMediaQuery('(max-width:769px)');
  const [modalShow, setModalShow] = useState(false);
  const isLandscape = ['header', 'footer'].includes(type) && !isMobile;

  const [imageURL, setImageURL] = useState(imageUrl);
  const [redirectLink, setRedirectLink] = useState(redirectUrl);
  const [numberOfSites, setNumberOfSites] = useState(0);
  const [hasError, setHasError] = useState({
    status: false,
    banner: false,
    link: false,
  });

  useEffect(() => {
    if (bannersSetted?.length > 0) {
      const [bannerObj] = bannersSetted.filter(
        (banner) => banner.type === type
      );

      if (bannerObj) {
        const { imageUrl, redirectUrl, nrOfSites } = bannerObj;
        setImageURL(() => imageUrl);
        setRedirectLink(() => redirectUrl);
        setNumberOfSites(() => nrOfSites);
      }
    }
  }, []);
  const pricePerSpot =
    allAvailableSpots.find((spot) => spot.bannerType === type)?.tokenPrice || 0;

  useEffect(() => {
    if (numberOfSites > 0) {
      if (type === 'popup') {
        setHasError((prevState) => ({
          ...prevState,
          status: !redirectLink,
          link: !redirectLink,
        }));
      } else {
        setHasError(() => ({
          status: !imageURL || !redirectLink,
          banner: !imageURL,
          link: !redirectLink,
        }));
      }
    } else {
      setHasError(() => ({
        status: false,
      }));
    }
  }, [numberOfSites, imageURL, redirectLink]);

  useEffect(() => {
    if (!hasError.status) {
      if (type === 'popup') {
        setBanner(type, {
          redirectUrl: redirectLink,
          nrOfSites: numberOfSites,
        });
      } else {
        setBanner(type, {
          imageUrl: imageURL,
          redirectUrl: redirectLink,
          nrOfSites: numberOfSites,
        });
      }
    }
  }, [hasError]);

  return (
    <MDBox
      position="relative"
      textAlign="center"
      mb={4}
      p={1.25}
      borderRadius="12px"
      display="flex"
      alignItems="center"
      maxWidth={isLandscape ? {} : { md: '30%' }}
      minWidth={isLandscape ? { xs: '100%' } : { xs: '100%', md: 'unset' }}
      sx={
        isLandscape
          ? { boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)' }
          : {
              flexWrap: 'wrap',
              boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
            }
      }
    >
      <MDBox sx={isLandscape ? { flex: '1' } : { minWidth: '100%' }}>
        <Stack
          direction={isLandscape ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
        >
          <MDTypography variant="h6" fontWeight="bold">
            {type}
          </MDTypography>
          <MDTypography
            variant="caption"
            fontWeight="regular"
            color="text"
            sx={isLandscape ? { ml: 4 } : { marginBottom: '20px' }}
          >
            {`Banner size: ${isLandscape ? '1920x250' : '200x400'}`}
          </MDTypography>
        </Stack>
        <MDBox
          position="relative"
          bgColor="grey"
          width="100%"
          borderRadius={8}
          display="flex"
          sx={{
            backgroundImage: `url(${imageURL ? imageURL : ''})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '194px',
            alignItems: 'end',
            justifyContent: 'center',
            ...(isLandscape && {
              height: '94px',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }),
          }}
        >
          <AvailableSpotsBadge>
            {(availableSpots && availableSpots[type]) || 0} Spots available
          </AvailableSpotsBadge>
          <Stack
            sx={{ width: '100%', height: '100%' }}
            direction={isLandscape ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
          >
            {!imageURL && (
              <MDButton
                size="small"
                variant="outlined"
                sx={{ mx: 'auto', my: 'auto' }}
                onClick={() => setModalShow(true)}
              >
                add image
              </MDButton>
            )}
            {hasError.status && <SitesError hasError={hasError} />}
          </Stack>
        </MDBox>
      </MDBox>
      <SitesControl
        isLandscape={isLandscape}
        maxAvailableSites={(availableSpots && availableSpots[type]) || 0}
        pricePerSpot={pricePerSpot}
        setTotalTokens={setTotalTokens}
        totalTokens={totalTokens}
        hasError={hasError}
        setHasError={setHasError}
        numberOfSites={numberOfSites}
        setNumberOfSites={setNumberOfSites}
        redirectLink={redirectLink}
        imageURL={imageURL}
      />
      <MDBox position="absolute" right="0" top="0">
        <IconButton color="black" onClick={() => setModalShow(true)}>
          <Icon fontSize="medium">edit</Icon>
        </IconButton>
      </MDBox>
      <ModalEditBanner
        type={type}
        isLandscape={isLandscape}
        initialImage={imageUrl}
        initialLink={redirectUrl}
        modalShow={modalShow}
        setModalShow={setModalShow}
        imageURL={imageURL}
        setImageURL={setImageURL}
        redirectLink={redirectLink}
        setRedirectLink={setRedirectLink}
        setNumberOfSites={setNumberOfSites}
        setHasError={setHasError}
      />
    </MDBox>
  );
};

export default BannerTemplate;

function AvailableSpotsBadge({ children }) {
  return (
    <MDBox
      bgColor="black"
      px={1.5}
      py={0.5}
      position="absolute"
      top={-10}
      left={15}
      borderRadius={6}
    >
      <MDTypography fontSize="14px" color="white" fontWeight="bold">
        {children}
      </MDTypography>
    </MDBox>
  );
}
