import React, { useState } from 'react';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useUserInvoices } from '../../../../utils/queries/queries';
import LoadingBasic from '../../../../components/LoadingBasic';
import InvoicesList from './InvoicesList';
import {
  isFromToday,
  isOlderThanYesterday,
  wasYesterday,
} from '../../utils/dateUtils';
import ModalAllInvoices from './ModalAllInvoices';
import { Stack } from '@mui/material';
import { walletButtonsStyle } from '../../utils/styling';

const InvoicesCard = () => {
  const { data: { allInvoicesUser } = {} } = useUserInvoices();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card sx={{ boxShadow: 4 }}>
        <Stack direction={'row'} justifyContent="space-between" mt={3} mx={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Your Invoices
          </MDTypography>
          <MDButton {...walletButtonsStyle} onClick={() => setShowModal(true)}>
            view all
          </MDButton>
        </Stack>
        <MDBox pt={3} pb={2} px={2}>
          {!allInvoicesUser ? (
            <LoadingBasic />
          ) : (
            <>
              <InvoicesList
                title={'Today'}
                invoices={allInvoicesUser
                  .filter((item) => isFromToday(item.dateCreated))
                  .reverse()}
              />
              <InvoicesList
                title={'Yesterday'}
                invoices={allInvoicesUser
                  .filter((item) => wasYesterday(item.dateCreated))
                  .reverse()}
              />
              <InvoicesList
                title={'Older'}
                invoices={allInvoicesUser
                  .filter((item) => isOlderThanYesterday(item.dateCreated))
                  .reverse()}
              />
            </>
          )}
        </MDBox>
      </Card>
      <ModalAllInvoices modalStatus={showModal} setModal={setShowModal}>
        {allInvoicesUser && (
          <InvoicesList
            title="All invoices"
            invoices={allInvoicesUser}
            short={false}
          />
        )}
      </ModalAllInvoices>
    </>
  );
};

export default InvoicesCard;
