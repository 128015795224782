import React, { useEffect } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 PRO React example components
import Sidenav from 'components/Sidenav';

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React routes
//disabled until app release
import routes from 'routes';
// import routes from './routesDisabled';

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from 'context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Images
import brandXS from './assets/images/Logo-XstreamTraffic.png';
import { UserProfileProvider } from 'pages/user_profile/userProfileContext';
import RequireAuth from './utils/routes/RequireAuth';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { layout } = controller;
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        if (route.auth) {
          return (
            <Route
              exact
              path={route.route}
              element={<RequireAuth>{route.component}</RequireAuth>}
              key={route.key}
            />
          );
        }
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProfileProvider>
        {layout === 'dashboard' && (
          <Sidenav
            color={'primary'}
            brand={brandXS}
            brandName="XStream Traffic"
            routes={routes}
          />
        )}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UserProfileProvider>
      <ToastContainer autoClose={2500} closeOnClick pauseOnHover />
    </ThemeProvider>
  );
}
