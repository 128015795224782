import MDBox from 'components/MDBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MDTypography from 'components/MDTypography';
import Divider from '@mui/material/Divider';
import { BasicCard } from './basicCard';
import { Stack } from '@mui/material';
import { useAdWizardContext } from '../../context';
import Countdown from 'react-countdown';

export function DurationCard() {
  const { start, setIsSessionExpired, setActiveStep } = useAdWizardContext();
  const standardDuration = 4; //hours

  const cancelSession = () => {
    setIsSessionExpired(true);
    setActiveStep(-1);
  };

  return (
    <BasicCard
      title="Duration"
      titleSuffix={`${standardDuration} hours`}
      icon={<AccessTimeIcon fontSize="medium" />}
    >
      <MDBox textAlign="center" mt={1} mb={2}>
        <Stack>
          <MDTypography variant="caption" fontWeight="bold">
            Your ad is scheduled
          </MDTypography>
          <MDTypography variant="caption" fontWeight="bold">
            to start in
          </MDTypography>
          <MDTypography variant="caption" fontWeight="bold" color="primary">
            {start ? (
              <Countdown
                date={start}
                renderer={({ minutes, seconds, completed }) => {
                  if (completed) {
                    return 'Session expired!';
                  } else {
                    return `${minutes} minute${
                      minutes === 1 ? '' : 's'
                    } ${seconds} second${seconds === 1 ? '' : 's'}`;
                  }
                }}
                onComplete={cancelSession}
              />
            ) : (
              ''
            )}
          </MDTypography>
        </Stack>
      </MDBox>
      <Divider />
      <MDBox
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        mx={1}
        mt={-0.5}
        mb={1.5}
      >
        <MDTypography variant="caption" fontWeight="bold">
          Start: {start?.getHours() ?? ''}:00
        </MDTypography>
        <MDTypography variant="caption" fontWeight="bold">
          End: {start?.getHours() + standardDuration ?? ''}:00
        </MDTypography>
      </MDBox>
    </BasicCard>
  );
}
