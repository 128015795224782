/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import React, { useEffect } from 'react';
import MDBox from 'components/MDBox';
import BannerTemplate from './BannerTemplate';
import { useAdWizardContext } from '../../context';
import { useAvailableSpots } from '../../../../utils/queries/queries';

const userBanners = [
  {
    type: 'header',
  },
  {
    type: 'sidebar',
  },
  {
    type: 'sidebar2',
  },
  {
    type: 'footer',
  },
];

function Banners() {
  const {
    banners: fetchBanners,
    selectedCat,
    selectedChannels,
    setSpotPrices,
  } = useAdWizardContext();

  const { availableSpots, allAvailableSpots, loading } = useAvailableSpots({
    category: selectedCat,
    channels: selectedChannels,
  });

  useEffect(() => {
    !loading && setSpotPrices(allAvailableSpots);
  }, [loading]);

  userBanners.forEach((userBanner, idx) => {
    const fetchBanner = fetchBanners.find(
      (banner) => banner.type === userBanner.type
    );
    userBanners[idx] = { ...userBanner, ...fetchBanner };
  });

  return (
    <MDBox
      textAlign="center"
      mx="auto"
      mt={4}
      mb={1}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {userBanners.map((item, idx) => (
        <BannerTemplate
          key={idx}
          {...item}
          availableSpots={availableSpots}
          allAvailableSpots={allAvailableSpots}
        />
      ))}
    </MDBox>
  );
}

export default Banners;
