import MDBox from 'components/MDBox';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import MDTypography from 'components/MDTypography';
import { BasicCard } from './basicCard';
import { useAdWizardContext } from 'pages/ad_wizard/context';

export function TotalCostCard() {
  const { totalTokens } = useAdWizardContext();
  return (
    <BasicCard
      title="Total cost"
      icon={<FavoriteBorderOutlinedIcon fontSize="medium" />}
    >
      <MDBox mt={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <MDTypography variant="h4" color="success">
          {totalTokens}
        </MDTypography>
        <MDTypography variant="h4" ml={1}>
          Tokens
        </MDTypography>
      </MDBox>
    </BasicCard>
  );
}
