import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { Stack } from '@mui/material';
import { SummaryLine } from './summaryLine';
import { BasicCard } from './basicCard';
import { useAdWizardContext } from 'pages/ad_wizard/context';

const capitalize = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export function SummaryCard() {
  const { bannersSetted } = useAdWizardContext();

  return (
    <BasicCard
      title="Your add will be displayed on"
      icon={<TaskAltOutlinedIcon fontSize="medium" />}
    >
      <Stack>
        {bannersSetted.length > 0 &&
          bannersSetted.map((banner, idx) => (
            <SummaryLine
              key={idx}
              number={banner.nrOfSites}
              text={`in the ${capitalize(banner.type)} section`}
            />
          ))}
      </Stack>
    </BasicCard>
  );
}
