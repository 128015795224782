/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * External dependencies
 */

// react-router-dom components
import React, { useRef } from 'react';

// @mui material components
import Card from '@mui/material/Card';

import { toast } from 'react-toastify';
/**
 * Internal dependencies
 */
import bgImage from 'assets/images/bg-xstream.png';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import useCreateUser from '../../utils/hooks/use-createUser';

import TermsCheckbox from './components/TermsCheckbox';
import PasswordField from './components/PasswordField';
import SocialSignIn from '../sign-in/components/SocialSignIn';

//Formik
import { Form, Formik } from 'formik';
import initialValues from './schemas/initialValues';
import form from './schemas/form';
import FormField from 'components/FormField';
import validations from './schemas/validations';
import { useLocation, useNavigate } from 'react-router-dom';
import AdultAgeCheckbox from './components/AdultAgeCheckbox';
import BasicLayout from '../../components/BasicLayout';

const SignUp = () => {
  const {
    formId,
    formField: { name, email, password, acceptTerms, adultAgeConfirm },
  } = form;
  const formikRef = useRef();

  const { createUser } = useCreateUser();

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (values, actions) => {
    const { name, email, password } = values;
    const res = createUser({
      variables: {
        input: {
          name,
          email,
          password,
          //TODO
          // password: crypto
          //   .createHash('sha256')
          //   .update(userInfo.password)
          //   .digest('hex'),
        },
      },
    });
    toast.promise(
      res,
      {
        pending: 'Work in progress...',
        success: {
          render() {
            navigate('/login', { replace: true });
            return 'User created. Please check your inbox and verify email!';
          },
        },
        error: {
          render({ data }) {
            return data.message;
          },
        },
      },
      {
        autoClose: false,
        onClose: () => {
          actions.setSubmitting(false);
          actions.resetForm();
        },
      }
    );
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: '280px', minHeight: '620px' }}>
        <SocialSignIn
          title="Join us today"
          description="Enter your email and password to register, or continue with"
        />
        <MDBox pt={4} pb={3} px={2}>
          <Formik
            innerRef={formikRef}
            initialValues={
              location.state
                ? {
                    name: location.state.username,
                    email: location.state.email,
                    password: location.state.password,
                    adultAgeConfirm: true,
                    acceptTerms: true,
                  }
                : initialValues
            }
            onSubmit={handleSubmit}
            validationSchema={validations}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <FormField
                  type={name.type}
                  label={name.label}
                  name={name.name}
                />
                {!location.state?.socialRegister && (
                  <>
                    <FormField
                      type={email.type}
                      label={email.label}
                      name={email.name}
                      value={values.email.toLowerCase()}
                    />
                    <PasswordField
                      formData={{
                        formField: { name, email, password, acceptTerms },
                        values,
                        touched,
                        errors,
                      }}
                    />
                  </>
                )}
                <AdultAgeCheckbox {...adultAgeConfirm} />
                <TermsCheckbox {...acceptTerms} />
                <MDButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="gradient"
                  color="primary"
                  fullWidth
                >
                  sign up
                </MDButton>
              </Form>
            )}
          </Formik>
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() => {
              navigate('/login');
            }}
            sx={{ mt: 3 }}
          >
            back to login
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default SignUp;
