/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Images
import bgImage from 'assets/images/bg-xstream.png';
import BasicLayout from '../../components/BasicLayout';
import React from 'react';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import { Form, Formik } from 'formik';
import form from '../sign-in/schemas/form';
import FormField from '../../components/FormField';
import { userForgotPassword } from 'utils/mutations/mutations';
import Loading from 'pages/user_profile/loading';
import { toast } from 'react-toastify';
import ShieldMain from '../../components/ShieldMain/ShieldMain';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const navigate = useNavigate();
  const {
    formId,
    formField: { email },
  } = form;

  const { useForgotPassword, loading } = userForgotPassword();

  if (loading) {
    return <Loading />;
  }

  const handleReset = (values, actions) => {
    const { email } = values;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const res = useForgotPassword({
      variables: {
        email: email,
      },
    });

    navigate('/login', { replace: true });

    toast.promise(res, {
      pending: 'In progress',
      success: 'Please check your inbox!',
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: '280px', minHeight: '620px' }}>
        <ShieldMain title="XStream Traffic" description="Password Reset" />
        <Formik
          initialValues={initialValues}
          onSubmit={handleReset}
          validationSchema={validations}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={formId} autoComplete="off">
              <Stack px={3} py={4} spacing={14.7}>
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.value.toLowerCase());
                  }}
                />

                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  reset password
                </MDButton>
              </Stack>
            </Form>
          )}
        </Formik>
        <MDBox px={3}>
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() => {
              navigate('/login');
            }}
          >
            back to login
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
