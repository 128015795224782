import List from '@mui/material/List';
import { ListSubheader } from '@mui/material';
import MDBox from '../../../../components/MDBox';
import InvoiceListItem from './InvoiceListItem';
import NoInvoiceListItem from './NoInvoiceListItem';

function InvoicesList({ invoices, title = 'Invoices', short = true }) {
  return (
    <List subheader={<ListSubheader>{title}</ListSubheader>}>
      <MDBox
        maxHeight={short ? 215 : 530}
        sx={{ overflow: 'scroll', pr: short ? 1.25 : 2 }}
      >
        {!invoices.length ? (
          <NoInvoiceListItem />
        ) : (
          invoices.map((item, idx) => (
            <InvoiceListItem key={idx} invoice={item} />
          ))
        )}
      </MDBox>
    </List>
  );
}

export default InvoicesList;
