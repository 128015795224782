import * as Yup from 'yup';
import checkout from './form';

const {
  formField: { name, email, password, acceptTerms, adultAgeConfirm },
} = checkout;

export default Yup.object().shape({
  [name.name]: Yup.string()
    .required('Required')
    .min(3, 'At least 3 characters'),
  [email.name]: Yup.string()
    .required('Required')
    .email('Email must be a valid email!'),
  [password.name]: Yup.string()
    .required('Required')
    .min(8, 'At least 8 characters!')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)/,
      'Must contain at least one lowercase, one uppercase and one digit!'
    ),
  [acceptTerms.name]: Yup.boolean().oneOf([true]),
  [adultAgeConfirm.name]: Yup.boolean().oneOf([true]),
});
