import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export function BasicCard({ title, titleSuffix, icon, children }) {
  const minH = '150px';

  return (
    <Card sx={{ boxShadow: 4, minHeight: minH }}>
      <MDBox>
        <Grid container px={1.9} mb={1.5}>
          <Grid item xs lineHeight={0} pt={1.5} pr={1}>
            <MDBox sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <MDTypography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  letterSpacing: 0.15,
                }}
                color="secondary"
              >
                {title}
              </MDTypography>
              <MDTypography
                sx={{
                  fontSize: '13px',
                  fontWeight: 'bold',
                  letterSpacing: 0.15,
                }}
                color="success"
              >
                &nbsp;{titleSuffix}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs="auto" mt={-2.5}>
            <MDBox
              mt={0}
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="primary"
              color="white"
              width="60px"
              height="60px"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              {icon}
            </MDBox>
          </Grid>
        </Grid>
        <MDBox px={2} lineHeight={1.25}>
          {children}
        </MDBox>
      </MDBox>
    </Card>
  );
}
