import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'components/DashboardLayout';
import Footer from 'components/Footer';
import CurrentBalanceCard from './components/CurrentBalanceCard';
import RedeemVoucherCard from './components/RedeemVoucherCard';
import TransactionsCard from './components/TransactionsCard';
import Invoices from './components/InvoicesCard';
import React from 'react';

function UserWallet() {
  return (
    <DashboardLayout>
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        minHeight={{ lg: '80vh' }}
        mx="auto"
      >
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mt={-3}
                mb={1.5}
                mx={{ xs: 2, sm: 4 }}
                bgColor="primary"
                borderRadius="lg"
                minHeight="80px"
                textAlign="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MDTypography variant="h3" color="light" fontWeight="regular">
                  Wallet
                </MDTypography>
              </MDBox>
              <MDBox mx={{ xs: 2, sm: 4 }} my={3}>
                <Grid container rowSpacing={{ xs: 1, sm: 2 }}>
                  <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                      <CurrentBalanceCard />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RedeemVoucherCard />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TransactionsCard />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Invoices />
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserWallet;
