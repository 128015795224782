import { Card } from '@mui/material';
import { Grid } from '@mui/material';
import { Icon } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import PageLayout from 'components/PageLayout';

const CreateAdSuccess = () => {
  // const navigate = useNavigate();
  // const handleClick = () => navigate('/create-ad');

  return (
    <PageLayout>
      <MDBox pt={3} pb={8} maxWidth={950} mt={25} ml="auto" mr="auto">
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} mx={3} display="flex" justifyContent="center">
                <MDBox
                  mt={10}
                  mb={3}
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="primary"
                  color="white"
                  width="6rem"
                  height="6rem"
                  shadow="md"
                  borderRadius="lg"
                  variant="gradient"
                >
                  <Icon fontSize="large">{'thumb_up'}</Icon>
                </MDBox>
              </MDBox>
              <MDBox
                pb={2}
                px={2}
                minHeight={250}
                textAlign="center"
                lineHeight={1.25}
              >
                <MDTypography
                  variant="h4"
                  color="dark"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  congratulations!
                </MDTypography>
                <MDTypography
                  variant="h5"
                  color="dark"
                  fontWeight="bold"
                  mt={1}
                  mb={5}
                >
                  Your ad is scheduled to start!
                </MDTypography>
                {/*<Icon*/}
                {/*  color="primary"*/}
                {/*  fontSize="large"*/}
                {/*  sx={{ display: 'block', margin: 'auto' }}*/}
                {/*>*/}
                {/*  {'thumb_up'}*/}
                {/*</Icon>*/}

                <MDButton color="light" variant="gradient">
                  Preview
                </MDButton>
              </MDBox>
              <MDBox p={2}>
                <MDBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton variant="text" color="text">
                    <MDTypography> Go to My Campaigns</MDTypography>
                  </MDButton>
                  <MDButton variant="text" color="text">
                    <MDTypography>Close</MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default CreateAdSuccess;
