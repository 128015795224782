const { Modal } = require('@mui/material');
const { default: MDBox } = require('components/MDBox');

const ModalAllInvoices = ({ modalStatus, setModal, children }) => {
  return (
    <Modal
      open={modalStatus}
      onClose={() => setModal(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MDBox
        bgColor="white"
        width="100%"
        maxWidth="507px"
        maxHeight="80%"
        sx={{
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
          padding: '16px 8px',
          overflow: 'auto',
          borderRadius: '12px',
        }}
      >
        {children}
      </MDBox>
    </Modal>
  );
};

export default ModalAllInvoices;
