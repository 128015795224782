import { useField } from 'formik';
import MDBox from '../../../components/MDBox';
import Checkbox from '@mui/material/Checkbox';
import MDTypography from '../../../components/MDTypography';

const TermsCheckbox = ({ ...acceptTerms }) => {
  const [field] = useField(acceptTerms);
  return (
    <MDBox display="flex" alignItems="center" my={2}>
      <Checkbox disableRipple {...field} type="checkbox" sx={{ ml: -1.25 }} />
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="text"
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        I agree the&nbsp;
      </MDTypography>
      <MDTypography
        component="a"
        href="https://xstreamtraffic.com/terms-conditions/"
        target="_blank"
        variant="button"
        fontWeight="bold"
        color="primary"
        textGradient
      >
        Terms and Conditions
      </MDTypography>
    </MDBox>
  );
};

export default TermsCheckbox;
