/**
 * External dependencies
 */
import { useQuery } from '@apollo/client';

/**
 * Internal dependencies
 */
import { GET_USER } from '../queries/getUser';

export default function useGetUser() {
  const { loading, error, data } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}
