/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { CREATE_USER } from '../mutations/user-sign-up';

/**
 * Internal dependencies
 */

export default function useCreateUser() {
  const [createUser, { loading }] = useMutation(CREATE_USER);

  return { createUser, loading };
}
