import Footer from 'components/Footer';
import DashboardLayout from 'components/DashboardLayout';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDTypography from 'components/MDTypography';
import useAllPartners from '../../utils/hooks/use-allPartners';

const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

function PartnerPage() {
  const partners = useAllPartners()?.data?.allPartners ?? [];
  return (
    <DashboardLayout>
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        mx="auto"
        minHeight={{ lg: '80vh' }}
      >
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mt={-3}
                mb={1.5}
                mx={4}
                bgColor="primary"
                borderRadius="lg"
                minHeight="80px"
                textAlign="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MDTypography variant="h3" color="light" fontWeight="regular">
                  Our Partners
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container>
                  {partners.map((item) => {
                    const homepageUrl = isValidHttpUrl(item?.homepage)
                      ? item?.homepage
                      : `https://${item?.homepage}`;
                    return (
                      <Grid item xs={12} md={6} xl={3} key={item._id}>
                        <a href={homepageUrl} target="_blank" rel="noreferrer">
                          <MDBox
                            component="img"
                            src={item?.logo}
                            alt={item?.homepage}
                            width="100%"
                          />
                        </a>
                      </Grid>
                    );
                  })}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PartnerPage;
