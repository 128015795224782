/**
 * External dependencies
 */
import React from 'react';
import { ApolloProvider } from '@apollo/client';

/**
 * Internal dependencies
 */
import apolloClient from 'utils/apollo-client/apollo-client';
import useAuthContext from '../../authentication/use-AuthContext';

const ApolloClientProvider = ({ children }) => {
  const { sessionToken } = useAuthContext();

  const loginToken = sessionToken || '';
  return (
    <ApolloProvider client={apolloClient(loginToken)}>
      {children}
    </ApolloProvider>
  );
};

export default ApolloClientProvider;
