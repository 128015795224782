import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideocamIcon from '@mui/icons-material/Videocam';
import TokenIcon from '@mui/icons-material/Token';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  ListItemButton,
  Modal,
  Stack,
} from '@mui/material';
import MDButtonNoFocus from '../../../components/MDButtonNoFocus';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

const ShortId = require('id-shorter');
const idShortener = ShortId();

const SiteListButton = ({ children, onClick }) => {
  return (
    <MDButtonNoFocus
      size={'small'}
      variant={'outlined'}
      color={'secondary'}
      sx={{
        width: '105px',
        minHeight: 'unset',
        height: '5px',
        textAlign: 'left',
      }}
      onClick={onClick}
    >
      {children}
    </MDButtonNoFocus>
  );
};

function Bill({
  _id,
  // nrOfSites,
  category,
  preferredChannels,
  tokens,
  banners,
  duration,
}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const lod_str = require('lodash/string');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [bannerCounters, setBannerCounters] = useState({});
  const [nrOfSites, setNrOfSites] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listType, setListType] = useState('');
  const [siteList, setSiteList] = useState([]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    const sites = banners.filter((banner) => banner.type === listType);
    setSiteList(sites);
  }, [listType]);

  useEffect(() => {
    const startLocalDateTime = duration
      ? new Date(duration?.startDate)
      : duration;
    setDate(() =>
      startLocalDateTime ? startLocalDateTime.toLocaleDateString() : ''
    );
    setTime(() =>
      startLocalDateTime ? startLocalDateTime.toLocaleTimeString() : ''
    );
  }, [duration, duration?.startDate]);

  useEffect(() => {
    const startLocalDateTime = duration
      ? new Date(duration?.startDate)
      : duration;
    const endLocalDateTime = duration ? new Date(duration?.endDate) : duration;
    if (startLocalDateTime > Date.now()) {
      setStatus(0);
    } else if (endLocalDateTime < Date.now()) {
      setStatus(-1);
    } else {
      setStatus(1);
    }
  }, [status]);

  useEffect(() => {
    setBannerCounters(() => {
      const counters = {};
      banners?.forEach((banner) => {
        counters[banner.type] = (counters[banner.type] || 0) + 1;
      });
      return counters;
    });
    setNrOfSites(() => {
      const sites = new Set(banners.map((banner) => banner.siteId));
      return sites.size;
    });
  }, [banners]);

  const statusIcons = (status) => {
    switch (status) {
      case 0:
        return {
          titleIcon: <PauseCircleIcon color="warning" />,
          infoIcon: <AccessTimeIcon color="warning" fontSize="large" />,
          baseColor: '#ffefd1',
        };
      case 1:
        return {
          titleIcon: <PlayCircleIcon sx={{ color: '#66bb6a' }} />,
          infoIcon: <VideocamIcon color="success" fontSize="large" />,
          baseColor: '#dbffe8',
        };
      default:
        return {
          titleIcon: <StopCircleIcon color="primary" />,
          infoIcon: <InfoOutlinedIcon color="secondary" fontSize="large" />,
          baseColor: 'light',
        };
    }
  };

  const handleOpenModal = (type) => {
    setListType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSiteList([]);
    setListType('');
    setIsModalOpen(false);
  };

  return (
    <Card
      sx={{
        py: 1,
        borderRadius: 'lg',
        boxShadow: 3,
      }}
    >
      <MDBox width="100%">
        <Grid
          container
          justifyItems={'center'}
          justifyContent={'center'}
          direction={'row'}
        >
          <Grid
            item
            container
            xs={12}
            px={{ xs: 1, sm: 2 }}
            mb={2.5}
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" alignItems="center">
                <MDTypography lineHeight={1}>
                  {statusIcons(status).titleIcon}
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  sx={{ color: '#adb5bd' }}
                >
                  &nbsp;{`#${idShortener.encode(_id)}`}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color="secondary"
                >
                  Start time: {time} - {date}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item container xs={12} px={2} spacing={1} pb={0.75}>
            <Grid item xs={4} sm={1}>
              <MDBox
                py={0.5}
                display="grid"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                minHeight="65px"
                bgColor={statusIcons(status).baseColor}
                borderRadius="lg"
              >
                <MDTypography
                  sx={{ fontSize: 10 }}
                  lineHeight={0}
                  color="secondary"
                  align="center"
                >
                  Info
                </MDTypography>
                {statusIcons(status).infoIcon}
              </MDBox>
            </Grid>
            <Grid item xs={8} sm={5}>
              <MDBox display="flex">
                <MDTypography
                  variant="caption"
                  color="secondary"
                  sx={{ flexBasis: { xs: '55%', sm: '35%' } }}
                >
                  No. of sites:
                </MDTypography>
                <MDTypography variant="caption" color="secondary" align="right">
                  {nrOfSites}
                </MDTypography>
              </MDBox>
              <MDBox display="flex">
                <MDTypography
                  variant="caption"
                  color="secondary"
                  sx={{ flexBasis: { xs: '55%', sm: '35%' } }}
                >
                  Category:
                </MDTypography>
                {category.map((cat, i, { length }) => (
                  <MDTypography variant="caption" color="secondary" key={cat}>
                    {lod_str.capitalize(cat)}
                    {length - i - 1 ? `;\u00A0` : ''}
                  </MDTypography>
                ))}
              </MDBox>
              <MDBox display="flex">
                <MDTypography
                  variant="caption"
                  color="secondary"
                  sx={{ flexBasis: { xs: '55%', sm: '35%' } }}
                >
                  Service type:&nbsp;
                </MDTypography>
                <MDTypography variant="caption" color="secondary">
                  {preferredChannels?.join(', ') || ''}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={1}>
              <MDBox
                py={0.5}
                display="grid"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                minHeight="65px"
                bgColor="light"
                borderRadius="lg"
              >
                <MDTypography
                  sx={{ fontSize: 10 }}
                  lineHeight={0}
                  color="secondary"
                  align="center"
                >
                  Banners
                </MDTypography>
                <AdUnitsIcon fontSize="large" color="secondary" />
              </MDBox>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Stack spacing={0.2}>
                {bannerCounters.header && (
                  <SiteListButton onClick={() => handleOpenModal('header')}>
                    <MDTypography
                      variant="caption"
                      color="secondary"
                      sx={{ width: '70px' }}
                    >
                      {bannerCounters.header}x Header
                    </MDTypography>
                  </SiteListButton>
                )}
                {bannerCounters.footer && (
                  <SiteListButton onClick={() => handleOpenModal('footer')}>
                    <MDTypography
                      variant="caption"
                      color="secondary"
                      sx={{ width: '70px' }}
                    >
                      {bannerCounters.footer}x Footer
                    </MDTypography>
                  </SiteListButton>
                )}
                {bannerCounters.sidebar && (
                  <SiteListButton onClick={() => handleOpenModal('sidebar')}>
                    <MDTypography
                      variant="caption"
                      color="secondary"
                      sx={{ width: '70px' }}
                    >
                      {bannerCounters.sidebar}x Sidebar
                    </MDTypography>
                  </SiteListButton>
                )}
                {bannerCounters.sidebar2 && (
                  <SiteListButton onClick={() => handleOpenModal('sidebar2')}>
                    <MDTypography
                      variant="caption"
                      color="secondary"
                      sx={{ width: '70px' }}
                    >
                      {bannerCounters.sidebar2}x Sidebar2
                    </MDTypography>
                  </SiteListButton>
                )}
                {bannerCounters.popup && (
                  <MDTypography variant="caption" color="secondary">
                    {bannerCounters.popup}x Popup
                  </MDTypography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={1}>
              <MDBox
                width="100%"
                height="100%"
                minHeight="65px"
                pt={0.5}
                bgColor="light"
                borderRadius="lg"
                textAlign="center"
                lineHeight={{ sm: 0.75 }}
              >
                <MDTypography
                  sx={{ fontSize: 10 }}
                  lineHeight={1}
                  color="secondary"
                  align="center"
                >
                  Tokens
                </MDTypography>
                <TokenIcon color="secondary" />
                <MDTypography variant="h5">{tokens}</MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <Card sx={{ ...style, width: { sm: 425 }, minHeight: 155 }}>
          <CardHeader
            sx={{ pb: 0 }}
            avatar={
              <Avatar sx={{ bgcolor: '#e91e63' }}>
                <PlaylistPlayIcon />
              </Avatar>
            }
            title={
              <MDTypography
                variant={'body2'}
                fontWeight={'medium'}
                color={'text'}
              >{`Site list for ${listType} banners`}</MDTypography>
            }
            action={
              <IconButton
                size={'small'}
                // sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider variant={'middle'} />
          <MDBox mx={3} mb={3} sx={{ maxHeight: '200px', overflow: 'scroll' }}>
            <List>
              {siteList.map((site, idx) => {
                return (
                  <ListItemButton
                    key={idx}
                    component={status < 0 ? 'span' : 'a'}
                    href={site.siteDomain || ''}
                    target={'_blank'}
                  >
                    <ListItemText
                      primary={
                        <MDTypography
                          variant={'body2'}
                          fontWeight={'regular'}
                          color={'text'}
                        >
                          {site.siteName}
                        </MDTypography>
                      }
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </MDBox>
        </Card>
      </Modal>
    </Card>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  noGutter: PropTypes.bool,
};

export default Bill;
