import MDTypography from 'components/MDTypography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import theme from '../../../../assets/theme';
import { Stack } from '@mui/material';

const SitesControl = ({
  isLandscape,
  maxAvailableSites,
  setTotalTokens,
  totalTokens,
  numberOfSites,
  setNumberOfSites,
  hasError,
  setHasError,
  pricePerSpot,
  redirectLink,
  imageURL,
}) => {
  const handleIncrease = () => {
    if (numberOfSites === 0 && (!redirectLink || !imageURL)) {
      setHasError({ status: true, banner: true, link: true });
      return;
    }
    if (
      numberOfSites + 1 > maxAvailableSites ||
      hasError.banner ||
      hasError.link
    ) {
      return;
    }
    setNumberOfSites(numberOfSites + 1);
    setTotalTokens(totalTokens + pricePerSpot);
    setHasError({ status: true });
  };

  const handleDecrease = () => {
    if (numberOfSites === 0) {
      return;
    }
    setNumberOfSites(numberOfSites - 1);
    setTotalTokens(totalTokens - pricePerSpot);
    setHasError({ status: false });
  };

  return (
    <Stack sx={isLandscape ? { ml: 2 } : { mx: 'auto', mt: 2, mb: 1 }}>
      <Stack {...stackStyle}>
        <IconButton onClick={handleIncrease}>
          <AddIcon {...iconStyle} />
        </IconButton>
        <MDTypography variant="caption" color="white" fontWeight="bold">
          {numberOfSites} Sites
        </MDTypography>
        <IconButton onClick={handleDecrease}>
          <RemoveIcon {...iconStyle} />
        </IconButton>
      </Stack>
      <MDTypography fontSize="12px" textAlign="center">
        select number of sites
      </MDTypography>
    </Stack>
  );
};

export default SitesControl;

const iconStyle = {
  fontSize: 'small',
  sx: {
    width: 35,
    borderRadius: 1,
    stroke: 'white',
    strokeWidth: 3,
    backgroundColor: theme.palette.primary.main,
  },
};

const stackStyle = {
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  sx: {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 1.5,
    height: 28,
    minWidth: 155,
  },
};
