import React, { useContext, useState } from 'react';
import {
  adultLimitDate,
  getArrDate,
  getStringDate,
} from '../../utils/dateUtils/dateUtils';
import { sites, avatarImg } from '../../utils/data/Fill-InAndOtherData';

const UserWizardContext = React.createContext();

const newUserData = {
  _id: '',
  email: '',
  name: '',
  username: '',
  gender: 'FEMALE',
  birthDate: getStringDate(adultLimitDate),
  socials: {
    instagram: '',
    twitter: '',
    snapchat: '',
    onlyfans: '',
  },
  avatar: avatarImg[0],
  blockCountries: [],
  workingSites: [],
};

export const UserWizardProvider = ({ children }) => {
  const [userData, setUserData] = useState(newUserData);

  const handleUserName = (username) => {
    let newUser = { ...userData, username };
    setUserData(newUser);
  };

  const handleGender = (newValue) => {
    const gender = newValue.toUpperCase();
    let newUser = { ...userData, gender };
    setUserData(newUser);
  };

  const handleBirthDate = (birthDate) => {
    const stringDate = getStringDate(birthDate);
    let newUser = { ...userData, birthDate: stringDate };
    setUserData(newUser);
  };

  const handleblockCountries = (blockCountries) => {
    let newUser = { ...userData, blockCountries };
    setUserData(newUser);
  };

  const checkBirthDate = () => {
    const [DD, MM, YYYY] = getArrDate(userData.birthDate);
    const currentBirthDate = new Date(YYYY, MM, DD);
    if (currentBirthDate > adultLimitDate) {
      return false;
    }
    return true;
  };

  const selectAvatar = (id) => {
    const newUser = { ...userData, avatar: id };
    setUserData(newUser);
  };

  const checkWSites = () => {
    return !userData.workingSites.length;
  };

  function lockButton(stepIndex) {
    switch (stepIndex) {
      case 0:
        return !checkBirthDate();
      case 1:
        return false;
      case 2:
        return checkWSites();
      default:
        return true;
    }
  }

  const toggleWorkingSites = (e) => {
    let name = e.target.name;
    let wSites;
    if (userData.workingSites.includes(name)) {
      wSites = userData.workingSites.filter((item) => item !== name);
    } else {
      wSites = [...userData.workingSites, name];
    }
    const newUserData = { ...userData, workingSites: wSites };
    setUserData(newUserData);
  };

  return (
    <UserWizardContext.Provider
      value={{
        userData,
        setUserData,
        handleUserName,
        handleGender,
        handleBirthDate,
        handleblockCountries,
        checkBirthDate,
        selectAvatar,
        lockButton,
        sites,
        toggleWorkingSites,
      }}
    >
      {children}
    </UserWizardContext.Provider>
  );
};

export const useUserWizardContext = () => {
  return useContext(UserWizardContext);
};
