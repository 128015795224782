import avatar from 'assets/images/avatar/avatar.jpeg';
import avatar1 from 'assets/images/avatar/avatar1.jpeg';
import avatar2 from 'assets/images/avatar/avatar2.jpeg';
import avatar3 from 'assets/images/avatar/avatar3.jpeg';
import avatar4 from 'assets/images/avatar/avatar4.jpeg';
import avatar5 from 'assets/images/avatar/avatar5.jpeg';
import avatar6 from 'assets/images/avatar/avatar6.jpeg';
import avatar7 from 'assets/images/avatar/avatar7.jpeg';
import avatar8 from 'assets/images/avatar/avatar8.jpeg';

export const sites = [
  { id: 1, name: 'chaturbate', label: 'Chaturbate' },
  { id: 2, name: 'myfreecams', label: 'Myfreecams' },
  { id: 3, name: 'bongacams', label: 'Bongacams' },
  { id: 4, name: 'imlive', label: 'Imlive' },
  { id: 5, name: 'livejasmin', label: 'Livejasmin' },
  { id: 6, name: 'flirt4free', label: 'Flirt4free' },
  { id: 7, name: 'streamate', label: 'Streamate' },
  { id: 8, name: 'xlovecam', label: 'Xlovecam' },
  { id: 9, name: 'stripchat', label: 'Stripchat' },
  { id: 10, name: 'camsoda', label: 'Camsoda' },
  { id: 11, name: 'cams.com', label: 'Cams.com' },
  { id: 12, name: 'onlyfans', label: 'onlyfans' },
  { id: 13, name: 'justfor.fans', label: 'Justfor.fans' },
  { id: 14, name: 'iwantclips', label: 'Iwantclips' },
];

export const socialAcc = [
  { id: 1, name: 'instagram', label: 'Instagram' },
  { id: 2, name: 'twitter', label: 'Twitter' },
  { id: 3, name: 'snapchat', label: 'Snapchat' },
  { id: 4, name: 'onlyfans', label: 'Only fans' },
];

// export const avatarImg = [
//   { id: 1, src: 'https://picsum.photos/id/77/100' },
//   { id: 2, src: 'https://picsum.photos/id/87/100' },
//   { id: 3, src: 'https://picsum.photos/id/95/100' },
//   { id: 4, src: 'https://picsum.photos/id/147/100' },
//   { id: 5, src: 'https://picsum.photos/id/117/100' },
//   { id: 6, src: 'https://picsum.photos/id/127/100' },
// ];

export const avatarImg = [
  avatar,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
];
