import MDBox from 'components/MDBox';
import DashboardLayout from 'components/DashboardLayout';
import BillGroup from './components/BillGroup';
import { Card } from '@mui/material';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
import pxToRem from '../../assets/theme/functions/pxToRem';
import Footer from '../../components/Footer';
import useAllCampaignsUser from '../../utils/hooks/use-allCampaignsUser';
import LoadingBasic from '../../components/LoadingBasic';

const MyCampaigns = () => {
  const { data, loading } = useAllCampaignsUser();

  const allCampaignsUser =
    data?.allCampaignsUser.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    }) ?? [];

  return (
    <DashboardLayout>
      <MDBox
        mt={{ xs: 7, sm: 15 }}
        maxWidth={950}
        minHeight={{ md: '70vh', lg: '80vh' }}
        mx="auto"
      >
        <Grid container justifyContent="center" sx={{ my: 4, marginTop: '0' }}>
          <Grid item xs={12}>
            <Card
              sx={{
                pb: pxToRem(25),
                maxHeight: { sm: '755px' },
              }}
            >
              <MDBox
                mt={-3}
                mb={1.5}
                mx={4}
                bgColor="primary"
                borderRadius="lg"
                minHeight="80px"
                textAlign="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MDTypography variant="h3" color="light" fontWeight="regular">
                  Campaigns
                </MDTypography>
              </MDBox>

              <MDBox
                p={{ xs: 2.5, md: 4 }}
                sx={{
                  overflow: { sm: 'scroll' },
                }}
              >
                {loading || !data ? (
                  <MDBox
                    height={{ xs: '35vh', md: '30vh', lg: '40vh' }}
                    display="flex"
                    justifyContent="center"
                  >
                    <LoadingBasic />
                  </MDBox>
                ) : (
                  <BillGroup userCampaigns={allCampaignsUser} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default MyCampaigns;
