/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import { Stack, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

function DefaultPricingCard({
  color,
  badge,
  price,
  specifications,
  actions,
  shadow,
}) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        mr={2}
        mt={-0.125}
      >
        <MDTypography
          variant="body1"
          color={color === 'white' ? 'text' : 'white'}
          sx={{ lineHeight: 0 }}
        >
          <Icon>{includes ? 'done' : 'remove'}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === 'white' ? 'text' : 'white'}
        fontWeight="regular"
      >
        {label}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ boxShadow: 2 }}>
      <MDBox
        bgColor={color}
        variant={color === 'white' ? 'contained' : 'gradient'}
        borderRadius="xl"
      >
        <MDBox
          bgColor={badge.color}
          width="max-content"
          px={4}
          pt={0}
          pb={0.5}
          mx="auto"
          mt={-1.375}
          borderRadius="section"
          lineHeight={1}
        >
          <MDTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            color={badge.color === 'light' ? 'dark' : 'white'}
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2} textAlign="center">
          <MDBox my={1}>
            <MDTypography
              variant="h1"
              color={color === 'white' ? 'dark' : 'white'}
            >
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MDTypography>
              {price.value}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3}>
          {renderSpecifications}
          <Stack spacing={1}>
            {actions.map((action, idx) => {
              switch (action.type) {
                case 'disabled':
                  return (
                    <MDBox mt={3} key={idx} mx="auto">
                      <MDButton
                        disabled
                        variant="gradient"
                        color={action.color}
                        sx={{ width: '150px', borderRadius: 5 }}
                      >
                        {action.label}
                      </MDButton>
                    </MDBox>
                  );
                case 'paxum':
                  return (
                    <Tooltip title="Paxum Buy" key={idx}>
                      <form
                        action="https://secure.paxum.com/payment/phrame.php?action=displayProcessPaymentLogin"
                        method="post"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          type="hidden"
                          name="button_id"
                          value={`${action.paxumId}`}
                        />
                        <input
                          type="image"
                          border="0"
                          name="submit"
                          alt="Paxum Buy"
                          src="https://secure.paxum.com/payment/images/payment/button_buynow_en.gif"
                        />
                      </form>
                    </Tooltip>
                  );
                default:
                  return (
                    <MDBox mt={3} key={idx} mx="auto">
                      <MDButton
                        onClick={action.handler}
                        variant="gradient"
                        color={action.color}
                        sx={{ width: '150px', borderRadius: 5 }}
                      >
                        {action.label}
                      </MDButton>
                    </MDBox>
                  );
              }
            })}
          </Stack>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: 'white',
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'white',
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.array,
  shadow: PropTypes.bool,
};

export default DefaultPricingCard;
