import { Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react';
import Bill from './Bill';
import MDTypography from '../../../components/MDTypography';
import { useNavigate } from 'react-router-dom';
import MDButton from '../../../components/MDButton';

function BillGroup({ userCampaigns }) {
  const navigate = useNavigate();
  const handleAdCampaign = () => {
    navigate('/create-ad', { replace: true });
  };
  return (
    <Stack spacing={1.5}>
      {userCampaigns.length ? (
        userCampaigns.map((campaign) => (
          <Bill key={campaign._id} {...campaign} />
        ))
      ) : (
        <MDBox textAlign="center" lineHeight={7} mt={3}>
          <MDTypography variant="h5" color="secondary" fontWeight="regular">
            Let's get started, create a new campaign!
          </MDTypography>
          <MDButton
            disableRipple
            variant="outlined"
            color="primary"
            onClick={handleAdCampaign}
          >
            create ad
          </MDButton>
        </MDBox>
      )}
    </Stack>
  );
}

export default BillGroup;
