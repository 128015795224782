import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  ListItem,
  ListItemAvatar,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import colors from '../../../../assets/theme/base/colors';
import { dateToDisplay } from '../../utils/dateUtils';

const ShortId = require('id-shorter');

function TransactionListItem({ invoice }) {
  const { amount, date, label, price, status } = invoice;

  return (
    <ListItem
      secondaryAction={tokensToDisplay(label, amount)}
      {...tokensToDisplayStyle(label, amount, status)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: colors.white.main }}>
          {getTransactionIcon(status)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={labelToDisplay(label, price)}
        primaryTypographyProps={primaryTypographyProps}
        secondary={dateToDisplay(date)}
        secondaryTypographyProps={secondaryTypographyProps}
      />
      {status === 'pending' && (
        <LightTooltip title={'Resume'} arrow={false}>
          <IconButton color={'success'} size={'small'}>
            <ReplayIcon />
          </IconButton>
        </LightTooltip>
      )}
    </ListItem>
  );
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function getTransactionIcon(status) {
  switch (status) {
    case 'pending':
      return <AccessTimeIcon color="warning" fontSize="medium" />;
    case 'done':
      return <CheckCircleOutlineIcon color="success" fontSize="medium" />;
    case 'canceled':
      return <HighlightOffIcon color="dark" fontSize="medium" />;
    default:
      return <ErrorOutlineIcon color="error" fontSize="medium" />;
  }
}

const labelToDisplay = (label, price) => {
  const idShortener = ShortId();
  const newLabel = !label.includes('Campaign') ? label : label.split(' ')[0];
  const shortId = label.includes('Campaign')
    ? '#' + idShortener.encode(label.split('#')[1]) + ' '
    : '';
  return `${newLabel} ${shortId}${
    label.includes('Tokens') ? `($ ${price || 0})` : ''
  }`;
};

const tokensToDisplay = (label, amount) =>
  /Campaign|remove/.test(label) ? `-${Math.abs(amount)}` : `+${amount}`;

const tokensToDisplayStyle = (label, amount, status) => {
  return {
    sx: {
      '& .MuiListItemSecondaryAction-root': {
        color:
          status === 'pending'
            ? colors.dark.main
            : Number(tokensToDisplay(label, amount)) < 0
            ? colors.error.main
            : colors.success.main,
        fontSize: 14,
        fontWeight: 'bold',
      },
    },
  };
};

const primaryTypographyProps = {
  color: colors.secondary.main,
  fontSize: 14,
  fontWeight: 'bold',
};

const secondaryTypographyProps = {
  color: colors.secondary.main,
  fontSize: 12,
};

export default TransactionListItem;
