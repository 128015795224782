/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * External dependencies
 */

import { useState } from 'react';
import { useNavigate } from 'react-router';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

/**
 * Internal dependencies
 */

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React example components
import Footer from 'components/Footer';

// Wizard page components
import UserInfo from 'pages/user_wizard/components/UserInfo';
import Socials from 'pages/user_wizard/components/Socials';
import WorkingSites from 'pages/user_wizard/components/WorkingSites';
import PageLayout from 'components/PageLayout';

import useUserWizard from '../../utils/hooks/use-userWizard';

//formik
import { Formik, Form } from 'formik';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import validations from './schemas/validations';
import apolloClient from '../../utils/apollo-client/apollo-client';
import { GET_USER } from '../../utils/queries/getUser';

function getSteps() {
  return ['User', 'Account', 'Sites'];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <Socials formData={formData} />;
    case 2:
      return <WorkingSites formData={formData} />;
    default:
      return null;
  }
}

function getStepTitle(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Get started by setting up your profile';
    case 1:
      return 'Add accounts';
    case 2:
      return 'Sites';
    default:
      return null;
  }
}

function UserWizard() {
  const { formId, formField } = form;
  const { userWizard } = useUserWizard();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    const email = (
      await apolloClient(
        JSON.parse(window.localStorage.getItem('sessionToken'))
      ).query({ query: GET_USER })
    ).data.user.email;

    const {
      day,
      month,
      year,
      instagram,
      twitter,
      snapchat,
      onlyfans,
      ...rest
    } = values;
    const socials = { instagram, twitter, snapchat, onlyfans };
    const payload = { ...rest, socials, email };
    userWizard({ variables: { input: { ...payload } } }).then(({ data }) => {
      const message = data.userWizard.message;
      if (message === 'success') {
        actions.setSubmitting(false);
        actions.resetForm();
        navigate('/user-profile-success', { replace: true });
      } else {
        alert(message);
      }
    });
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <PageLayout>
      <MDBox pt={3} pb={8} maxWidth={950} mx="auto">
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="regular">
                  {getStepTitle(activeStep)}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Formik
              initialValues={initialValues}
              validationSchema={validations[activeStep]}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card>
                    <MDBox mt={-3} mx={2}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={2}>
                      <MDBox minHeight={{ sm: 465 }}>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          setFieldValue,
                        })}
                      </MDBox>
                      <MDBox
                        mt={3}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          disabled={
                            isLastStep ? !values?.workingSites[0] : false
                          }
                        >
                          {isLastStep ? 'send' : 'next'}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default UserWizard;
