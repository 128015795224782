/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CHECK_USERNAME = gql`
  query checkUsernameQuery($username: String!) {
    checkUsername(username: $username) {
      username
      found
    }
  }
`;
