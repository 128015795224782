import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const SitesError = ({ hasError }) => {
  return (
    <MDBox
      borderRadius="12px"
      bgColor="white"
      sx={{
        boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '183px',
        padding: '20px',
        margin: '14px',
      }}
    >
      <MDBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <WarningAmberRoundedIcon sx={{ color: 'red', width: '100%' }} />
        <MDTypography sx={{ color: 'red', fontSize: '10px', minWidth: '100%' }}>
          Error
        </MDTypography>
      </MDBox>
      <MDBox textAlign="left">
        {hasError.banner && (
          <MDTypography sx={{ fontSize: '10px' }}>
            Missing banner Image
          </MDTypography>
        )}

        {hasError.link && (
          <MDTypography sx={{ fontSize: '10px' }}>
            Missing banner Link
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};

export default SitesError;
