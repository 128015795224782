import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import DefaultPricingCard from 'components/DefaultPricingCard';
import { useGenerateUserInvoice } from 'utils/mutations/mutations';
import { Stack } from '@mui/material';

const PricingCards = ({ prices }) => {
  const { generateUserInvoice } = useGenerateUserInvoice();
  const bonus = 2;

  const currencyToTokens = (price) => price * 20;
  const calculateBonus = (price) => price * bonus;
  const getBundleTitle = (idx) => {
    switch (idx) {
      case 0:
        return 'starter';
      case 1:
        return 'pro';
      case 2:
        return 'elite';
      default:
        return 'regular';
    }
  };

  const getPaxumId = (idx) => {
    switch (idx) {
      case 1:
        return 3915;
      case 2:
        return 3916;
      default:
        return 3914;
    }
  };

  const buyTokensWithCrypto = (e, price) => {
    e.preventDefault();
    const redirectUrl = generateUserInvoice({
      variables: {
        price: Number(price),
        currency: 'USD',
        merchant: 'litepay',
      },
    });

    toast.promise(redirectUrl, {
      pending: 'In progress',
      success: {
        render({ data }) {
          const url = data?.data?.invoiceUser;
          console.log(data, url);
          window.location = url;
          return 'Successful';
        },
      },
      error: 'Redirect failed!',
    });
  };

  return (
    <Stack
      mt={8}
      mb={{ xs: 3, sm: 4 }}
      mx={{ xs: 3, sm: 4 }}
      spacing={{ xs: 3, sm: 1.5 }}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      justifyContent="center"
    >
      {prices.map((price, idx) => (
        <DefaultPricingCard
          key={`${price}-${idx}`}
          color="white"
          badge={{
            color: 'light',
            label: `${getBundleTitle(idx)}`,
          }}
          price={{ currency: '$', value: price }}
          specifications={[
            { label: `${currencyToTokens(price)} tokens`, includes: true },
            {
              label: `Bundle bonus ${calculateBonus(price)} tokens`,
              includes: true,
            },
          ]}
          actions={[
            {
              type: 'internal',
              handler: (e) => buyTokensWithCrypto(e, price),
              color: 'dark',
              label: 'Crypto Buy',
            },
            {
              type: 'paxum',
              paxumId: getPaxumId(idx),
            },
          ]}
          shadow={false}
        />
      ))}
    </Stack>
  );
};

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
