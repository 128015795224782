import { gql, useLazyQuery, useQuery } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query getUserProfileQuery {
    user {
      _id
      updatedAt
      email
      username
      profile {
        registrationWizard
        name
        avatar
        gender
        birthDate
      }
      socialProfile {
        instagram
        twitter
        snapchat
        onlyfans
      }
      status {
        onlineAdminDash
        onlineUserDash
        lastLogin {
          userAgent
          ipAddr
          date
        }
      }
      blockCountries
      workingSites
      nrOfCampaigns
      tokens
      banners {
        type
        imageUrl
        redirectUrl
      }
    }
  }
`;

const GET_AVAILABLE_SPOTS = gql`
  query AllAvailableSpots($category: [String], $channels: [String]) {
    allAvailableSpots(category: $category, channels: $channels) {
      bannerType
      countOfSites
      tokenPrice
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query AllTransactionsUser {
    allTransactionsUser {
      _id
      userID
      label
      amount
      price
      status
      date
    }
  }
`;

export const GET_USER_INVOICES = gql`
  query AllInvoicesUser {
    allInvoicesUser {
      _id
      invoiceNumber
      dateCreated
      updatedAt
      userID
      details
      amount
      currency
      fileUrl
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkEmailQuery($email: String!) {
    checkEmail(email: $email) {
      email
      found
    }
  }
`;

export function useCheckEmail(email = '') {
  const [triggerCheckEmail, { data, loading, error, refetch }] = useLazyQuery(
    CHECK_EMAIL,
    {
      variables: {
        email: email,
      },
    }
  );

  return {
    triggerCheckEmail,
    data,
    loading,
    error,
    refetch,
  };
}

export function useUserTransactions() {
  const { data, loading, error } = useQuery(GET_USER_TRANSACTIONS, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useUserInvoices() {
  const { data, loading, error } = useQuery(GET_USER_INVOICES, {
    fetchPolicy: 'network-only',
  });

  return {
    data,
    loading,
    error,
  };
}

export function useUserProfile() {
  const { data, loading, error } = useQuery(GET_USER_PROFILE);

  return {
    data,
    loading,
    error,
  };
}

export function useAvailableSpots(options) {
  const { data, loading, error, refetch } = useQuery(GET_AVAILABLE_SPOTS, {
    variables: {
      category: options?.category || [],
      channels: options?.channels || [],
    },
    fetchPolicy: 'network-only',
  });

  const allAvailableSpots = data?.allAvailableSpots || [];
  const availableSpots = {};
  allAvailableSpots.forEach((item) => {
    availableSpots[item?.bannerType] = item?.countOfSites;
  });

  return {
    allAvailableSpots,
    loading,
    error,
    refetch,
    availableSpots,
  };
}
