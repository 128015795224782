/**
 * External dependencies
 */
import { useMutation } from '@apollo/client';
import { REDEEM_VOUCHER } from '../mutations/redeemVoucher';
import { GET_USER_TRANSACTIONS } from '../queries/queries';

/**
 * Internal dependencies
 */

export default function useRedeemVoucher() {
  const [redeemVoucher, { loading }] = useMutation(REDEEM_VOUCHER, {
    refetchQueries: [{ query: GET_USER_TRANSACTIONS }],
  });

  return { redeemVoucher, loading };
}
