import { useNavigate } from 'react-router';

import { Card } from '@mui/material';
import { Grid } from '@mui/material';
import { Icon } from '@mui/material';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import PageLayout from 'components/PageLayout';

const UserProfileSuccess = () => {
  const navigate = useNavigate();
  const handleCreateAd = () => navigate('/create-ad', { replace: true });
  const handleClose = () => navigate('/pages/user_profile', { replace: true });

  return (
    <PageLayout>
      <MDBox pt={3} pb={8} maxWidth={950} ml="auto" mr="auto">
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} mx={3} display="flex" justifyContent="center">
                <MDBox
                  mt={10}
                  mb={3}
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="primary"
                  color="white"
                  width="65px"
                  height="65px"
                  shadow="md"
                  borderRadius="lg"
                  variant="gradient"
                >
                  <Icon fontSize="large">{'thumb_up'}</Icon>
                </MDBox>
              </MDBox>
              <MDBox
                pb={2}
                px={2}
                minHeight={250}
                textAlign="center"
                lineHeight={1.25}
              >
                <MDTypography
                  variant="h5"
                  color="dark"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  yey!
                </MDTypography>
                <MDTypography variant="h6" color="dark" fontWeight="bold">
                  Your profile was successfuly created!
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <MDBox
                  mt={3}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton variant="text" onClick={handleCreateAd}>
                    <MDTypography variant="subtitle2">Create Ad</MDTypography>
                  </MDButton>
                  <MDButton variant="text" onClick={handleClose}>
                    <MDTypography variant="subtitle2">Close</MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default UserProfileSuccess;
