/**
 * External dependencies
 */
import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($input: createUserForm) {
    createUser(input: $input) {
      message
      node {
        _id
        updatedAt
        email
        profile {
          name
        }
      }
    }
  }
`;

export const USER_WIZARD = gql`
  mutation UserWizard($input: userWizardForm) {
    userWizard(input: $input) {
      message
      node {
        _id
        updatedAt
        status {
          onlineAdminDash
          onlineUserDash
        }
        email
        username
        socialProfile {
          instagram
          twitter
          snapchat
          onlyfans
        }
        blockCountries
        workingSites
        nrOfCampaigns
        banners {
          type
          imageUrl
        }
        profile {
          name
          avatar
          gender
          birthDate
        }
      }
    }
  }
`;
