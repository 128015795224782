/**
 * External dependencies
 */

/**
 * Internal dependencies
 */

import SignIn from 'pages/sign-in';
import SignUp from 'pages/sign-up';
import ResetPassword from 'pages/user_reset_pass';
import MyCampaigns from 'pages/user_campaigns';
import PricingPage from 'pages/pricing';
import PartnersPage from 'pages/partners';
import UserWallet from 'pages/user_wallet';
import UserProfile from 'pages/user_profile';
import AdWizard from 'pages/ad_wizard';
import UserWizard from 'pages/user_wizard';
import UserProfileSuccess from 'pages/user_wizard/components/UserProfileSuccess';
import CreateAdSuccess from 'pages/ad_wizard/components/CreateAdSuccess';
import NewPass from 'pages/user_new_pass/index';

import { UserWizardProvider } from './pages/user_wizard/context';
import { AdWizardProvider } from './pages/ad_wizard/context';
import VerifyEmail from './pages/verify-email/VerifyEmail';

const routes = [
  {
    type: 'hidden',
    name: 'Authentication',
    key: 'authentication',
    // icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        auth: false,
        name: 'Sign In',
        key: 'login',
        route: '/login',
        component: <SignIn />,
      },
      {
        auth: false,
        name: 'Sign Up',
        key: 'sign-up',
        route: '/sign-up',
        component: (
          <UserWizardProvider>
            <SignUp />,
          </UserWizardProvider>
        ),
      },
      {
        auth: false,
        name: 'Verify',
        key: 'verify',
        route: '/verify/token=:token',
        component: <VerifyEmail />,
      },
      {
        auth: true,
        name: 'User Wizard',
        key: 'user-wizard',
        route: '/user-wizard',
        component: <UserWizard />,
      },
      {
        auth: false,
        name: 'Reset Password',
        key: 'reset-password',
        route: '/reset-password',
        component: <ResetPassword />,
      },
      {
        auth: false,
        name: 'New Password',
        key: 'new-password',
        route: '/new-password/resetToken=:resetToken',
        component: <NewPass />,
      },
      {
        auth: true,
        name: 'User Profile Success',
        key: 'user-profile-success',
        route: '/user-profile-success',
        component: <UserProfileSuccess />,
      },
      {
        auth: true,
        name: 'Create Ad',
        key: 'create-ad',
        route: '/create-ad',
        component: (
          <AdWizardProvider>
            <AdWizard />
          </AdWizardProvider>
        ),
      },
      {
        auth: true,
        name: 'Create Ad Success',
        key: 'create-ad-success',
        route: '/create-ad-success',
        component: <CreateAdSuccess />,
      },
    ],
  },
  //active route highlighted <==> key === route substring after last slash
  {
    auth: true,
    type: 'collapse',
    name: 'profile', // do not change
    key: 'user_profile',
    route: '/pages/user_profile',
    component: <UserProfile />,
    noCollapse: true,
  },
  { type: 'divider', key: 'divider-0' },
  {
    auth: true,
    type: 'collapse',
    name: 'Campaigns',
    key: 'user_campaigns',
    route: '/pages/user_campaigns',
    component: <MyCampaigns />,
    noCollapse: true,
  },
  {
    auth: true,
    type: 'collapse',
    name: 'Create Ad',
    key: 'create-ad',
    route: '/create-ad',
    component: <AdWizard />,
    noCollapse: true,
  },
  {
    auth: true,
    type: 'collapse',
    name: 'Wallet',
    key: 'user_wallet',
    route: '/pages/user_wallet',
    component: <UserWallet />,
    noCollapse: true,
  },
  {
    auth: true,
    type: 'collapse',
    name: 'Pricing',
    key: 'pricing',
    route: '/pages/pricing',
    component: <PricingPage />,
    noCollapse: true,
  },
  {
    auth: true,
    type: 'collapse',
    name: 'Partners',
    key: 'partners',
    route: '/pages/partners',
    component: <PartnersPage />,
    noCollapse: true,
  },
  {
    auth: false,
    type: 'collapse',
    name: 'Logout', //do not change
    key: 'logout',
    route: '/login',
    component: <SignIn />,
    noCollapse: true,
  },
];

export default routes;
