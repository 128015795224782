/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Grid } from '@mui/material';
import React from 'react';

function FallBack() {
  document.body.style = 'background: #f9f2f0;';
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
      <Grid container>
        <Grid item xs={12}>
          <h1
            style={{
              fontSize: '25spx',
              mt: '10%',
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              fontFamily: 'Roboto',
            }}
          >
            Something went wrong!
          </h1>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <a href="home">
              <button
                style={{
                  fontFamily: 'Roboto',
                  boxShadow: '0px 2px 6px rgba(151, 172, 198, 0.25)',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  border: '0px solid white',
                  borderRadius: '8px',
                  backgroundColor: '#D1D6DC',
                  width: '150px',
                  height: '41px',
                  fontSize: '12px',
                  padding: '12px',
                }}
              >
                GO TO HOMEPAGE
              </button>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FallBack;
