/* eslint-disable react-hooks/exhaustive-deps */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from 'react';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import LogoutIcon from '@mui/icons-material/Logout';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupsIcon from '@mui/icons-material/Groups';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from '../MDAvatar';

// Material Dashboard 2 PRO React example components
import SidenavItem from 'components/Sidenav/SidenavItem';
import SidenavRoot from 'components/Sidenav/SidenavRoot';

// Custom styles for the Sidenav
import sidenavLogoLabel from 'components/Sidenav/styles/sidenav';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav } from 'context';
import useAuthContext from '../../authentication/use-AuthContext';
import { useProfile } from '../../pages/user_profile/userProfileContext';
import Link from '@mui/material/Link';

const Sidenav = ({ color, brand, brandName, routes, ...rest }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const items = pathname.split('/').slice(1);
  const itemName = items[items.length - 1];

  const { removeSessionToken } = useAuthContext();

  const textColor = 'white';

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  const handleLogout = (e) => {
    if (e.currentTarget.name.toLowerCase() === 'logout') {
      removeSessionToken();
    }
  };

  const { user: userData } = useProfile();

  const getRouteIcon = (name, size = 'small', color = 'white') => {
    let routeIcon;
    switch (name.toLowerCase()) {
      case 'profile':
        routeIcon = (
          <MDAvatar src={userData?.profile?.avatar} alt={name} size="sm" />
        );
        break;
      case 'campaigns':
        routeIcon = <BubbleChartIcon fontSize={size} color={color} />;
        break;
      case 'create ad':
        routeIcon = <LiveTvIcon fontSize={size} color={color} />;
        break;
      case 'wallet':
        routeIcon = <AccountBalanceWalletIcon fontSize={size} color={color} />;
        break;
      case 'pricing':
        routeIcon = <MonetizationOnIcon fontSize={size} color={color} />;
        break;
      case 'partners':
        routeIcon = <GroupsIcon fontSize={size} color={color} />;
        break;
      case 'logout':
        routeIcon = <LogoutIcon fontSize={size} color={color} />;
        break;
      default:
        routeIcon = null;
    }
    return routeIcon;
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === 'collapse') {
        if (noCollapse && route) {
          const linkName = name === 'profile' ? userData?.username : name;
          const routeIcon = getRouteIcon(name);
          returnValue = (
            <NavLink
              to={route}
              key={key}
              sx={{ textDecoration: 'none' }}
              name={linkName}
              onClick={handleLogout}
            >
              <SidenavItem
                color={color}
                name={linkName}
                active={key === itemName}
                icon={routeIcon}
              />
            </NavLink>
          );
        }
      } else if (type === 'divider') {
        returnValue = <Divider key={key} light={true} sx={{ my: 0 }} />;
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav }}>
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          // display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <Link href="https://xstreamtraffic.com/" target="_blank">
            {brand && (
              <MDBox
                component="img"
                src={brand}
                alt="Brand"
                width="2.5rem"
                ml={-0.5}
                mr={1}
                my={-2}
              />
            )}
          </Link>
          <MDBox
            width={!brandName && '100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider light={true} sx={{ mb: 0 }} />
      <MDBox px={1.5}>
        <List>{renderRoutes}</List>
      </MDBox>
    </SidenavRoot>
  );
};

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'primary',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
