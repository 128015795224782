/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Wizard application components
// Images
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { useAdWizardContext } from '../../context';
import { categories, subCat } from '../../../../utils/data/cat_subCat';
import MDInput from 'components/MDInput';

const maxCat = 2; //max selected categories
const maxSub = 3; //max selected subcategories
const maxChan = 2; //max selected channels
const hMin = 66;
const hMinMob = 140;

function Audience() {
  const {
    selectedCat,
    setSelectedCat,
    selectedSub,
    setSelectedSub,
    selectedChannels,
    setSelectedChannels,
  } = useAdWizardContext();

  const toggleCatSelect = (e) => {
    const name = e.target.name;
    let newSelection;
    if (selectedCat.includes(name)) {
      newSelection = selectedCat.filter((item) => item !== name);
    } else {
      newSelection = [...selectedCat, name];
    }
    setSelectedCat(newSelection);
  };

  const handleSubSelect = (newValue) => {
    setSelectedSub(newValue);
  };

  const toggleChanSelect = (e) => {
    const name = e.target.name;
    let newSelection;
    if (selectedChannels.includes(name)) {
      newSelection = selectedChannels.filter((item) => item !== name);
    } else {
      newSelection = [...selectedChannels, name];
    }
    setSelectedChannels(newSelection);
  };

  return (
    <MDBox px={{ xs: 1, sm: 2 }} py={4}>
      <MDBox mb={3}>
        <MDTypography variant="h4" fontWeight="regular">
          Select Audience
        </MDTypography>
      </MDBox>
      <Grid container columns={16}>
        <Grid item xs={6}>
          <MDBox minHeight={{ xs: hMinMob, sm: hMin }}>
            <MDTypography mb={1} variant="h6" color="text" display="block">
              Select your category
            </MDTypography>
            {!selectedCat.length && (
              <MDTypography variant="caption" color="error" display="block">
                Required at least one category
              </MDTypography>
            )}
            {selectedCat.length < maxCat && (
              <MDTypography variant="caption" color="success" display="block">
                You can select 1 or 2
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs pl={{ xs: 2, sm: 1.5 }}>
          <FormGroup sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            {categories.map((item) => {
              const isDisabled =
                selectedCat.length >= maxCat && !selectedCat.includes(item);
              return (
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '13px',
                      fontWeight: 'regular',
                      color: '#7b809a',
                      ml: -0.25,
                    },
                    '& .MuiFormControlLabel-label.Mui-disabled': {
                      color: '#dbdbdb',
                    },
                  }}
                  key={item}
                  label={item}
                  disabled={isDisabled}
                  control={
                    <Checkbox
                      disableRipple
                      name={item}
                      checked={selectedCat.includes(item)}
                      onChange={toggleCatSelect}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container mt={{ xs: 1, sm: 5 }} columns={16}>
        <Grid item xs={16} sm={6}>
          <MDBox minHeight={hMin}>
            <MDTypography mb={1} variant="h6" color="text" display="block">
              Select your tags
            </MDTypography>
            {/*{!selectedSub.length && (*/}
            {/*  <MDTypography variant="caption" color="error" display="block">*/}
            {/*    Required at least one sub-category*/}
            {/*  </MDTypography>*/}
            {/*)}*/}
            {selectedSub.length < maxSub && (
              <MDTypography variant="caption" color="success" display="block">
                You can select up to 3
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={16} sm={9} lg={8} pl={{ sm: 1.5 }}>
          <Autocomplete
            multiple
            value={selectedSub}
            getOptionDisabled={(option) =>
              selectedSub.length >= 3 && !selectedSub.includes(option)
            }
            onChange={(e, newValue) => {
              handleSubSelect(newValue);
            }}
            options={subCat}
            renderInput={(params) => (
              <MDInput
                label=""
                variant="standard"
                fullWidth
                {...params}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container mt={5} columns={16}>
        <Grid item xs={6}>
          <MDBox minHeight={{ xs: hMinMob, sm: hMin }}>
            <MDTypography mb={1} variant="h6" color="text" display="block">
              Preferred channels
            </MDTypography>
            {!selectedChannels.length && (
              <MDTypography variant="caption" color="error" display="block">
                Required at least one channel
              </MDTypography>
            )}
            {selectedChannels.length < maxChan && (
              <MDTypography variant="caption" color="success" display="block">
                You can select 1 or 2
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs pl={{ xs: 2, sm: 1.5 }}>
          <FormGroup sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                  fontWeight: 'regular',
                  color: '#7b809a',
                  ml: -0.25,
                },
              }}
              control={
                <Checkbox
                  disableRipple
                  name="livecam"
                  checked={selectedChannels.includes('livecam')}
                  onChange={toggleChanSelect}
                />
              }
              label="Livecams"
            />
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '13px',
                  fontWeight: 'regular',
                  color: '#7b809a',
                  ml: -0.25,
                },
              }}
              control={
                <Checkbox
                  disableRipple
                  name="tube"
                  checked={selectedChannels.includes('tube')}
                  onChange={toggleChanSelect}
                />
              }
              label="Tubes"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Audience;
