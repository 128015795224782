import { Card } from '@mui/material';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';
import MDButton from '../../../../components/MDButton';
import { useNavigate } from 'react-router-dom';
import useGetUser from '../../../../utils/hooks/use-getUser';
import LoadingBasic from '../../../../components/LoadingBasic';
import { walletButtonsStyle } from '../../utils/styling';

const CurrentBalanceCard = () => {
  const {
    data: { user: { tokens = 0 } = {} } = {},
    loading,
    error,
  } = useGetUser();
  const navigate = useNavigate();

  return (
    <Card
      position="relative"
      sx={{ boxShadow: 4, minHeight: { xs: 0, sm: 205 } }}
    >
      <MDBox mx={2} mt={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Current balance
        </MDTypography>
      </MDBox>
      {loading || error ? (
        <LoadingBasic />
      ) : (
        <>
          <MDBox
            mt={{ xs: 2, sm: 5 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MDTypography variant="h3" fontWeight="bold" color="primary">
              {tokens} Tokens
            </MDTypography>
          </MDBox>
          <MDBox mx={'auto'} mb={2} mt={{ xs: 2, sm: 3.4 }}>
            <MDButton
              {...walletButtonsStyle}
              onClick={() => navigate('/pages/pricing')}
            >
              buy more
            </MDButton>
          </MDBox>
        </>
      )}
      <MDBox
        position="absolute"
        top="-12px"
        right="13px"
        bgColor="primary"
        sx={{
          width: '48px',
          height: '48px',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <CheckCircleOutlineIcon
          color="white"
          sx={{ width: '1.75rem', height: '1.75rem' }}
        />
      </MDBox>
    </Card>
  );
};

export default CurrentBalanceCard;
