/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  DialogContent,
  Input,
  Modal,
  Slider,
  Stack,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import React, { useState } from 'react';
import MDButton from 'components/MDButton';
import getCroppedImg from 'utils/cropImage';
import { v4 as uuidv4 } from 'uuid';
import uploadFile from 'utils/firebase/uploadFile';
import Cropper from 'react-easy-crop';
import { useProfile } from 'pages/user_profile/userProfileContext';
import { useAdWizardContext } from '../../context';
import { toast } from 'react-toastify';

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};

const ModalEditBanner = ({
  modalShow,
  setModalShow,
  isLandscape,
  imageURL,
  setImageURL,
  redirectLink,
  setRedirectLink,
  type,
  setNumberOfSites,
  initialImage,
  initialLink,
}) => {
  const { user: userData } = useProfile();
  const { allPartners, removeBanner } = useAdWizardContext();
  const [file, setFile] = useState(initialImage);

  // const [photoURL, setPhotoURL] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isDomainValid, setIsDomainValid] = useState(false);

  const checkDomain = (domain) => {
    const domainList = allPartners.map((partner) => partner.homepage);
    return domainList.includes(domain);
  };

  const handleCancellation = () => {
    setModalShow(false);
    if (!isDomainValid) {
      setFile(initialImage);
      setImageURL(initialImage);
      // setPhotoURL(initialImage);
      setRedirectLink(initialLink);
      removeBanner(type);
      setNumberOfSites(0);
    }
  };

  const handleSave = () => {
    setIsDomainValid(false);
    let domain;
    try {
      domain = new URL(redirectLink).hostname.replace('www.', '');
    } catch (e) {
      toast.error(e.message.replace('URL constructor: ', ''));
      return;
    }
    if (!checkDomain(domain)) {
      toast.error(
        "The URL you've entered is not permitted, please check our partner page for details."
      );
      return;
    }
    // if (type !== 'popup' && file !== photoURL) cropImage();
    if (type !== 'popup' && file !== imageURL) cropImage();
    setIsDomainValid(true);
    setModalShow(false);
  };

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      // setPhotoURL(URL.createObjectURL(file));
      setImageURL(URL.createObjectURL(file));
    }
  };

  const resetCrop = () => {
    setZoom(1);
    setRotation(0);
  };

  const cropImage = async () => {
    // const { file } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
    const { file } = await getCroppedImg(imageURL, croppedAreaPixels, rotation);

    if (file) {
      const imageName = uuidv4() + '.' + file?.name?.split('.')?.pop();
      const url = await uploadFile(
        file,
        `banners/${userData?._id}/${imageName}`
      );
      setImageURL(url);
    }
  };

  const rotateLeft = () => {
    if (rotation >= -170) {
      setRotation((prevState) => prevState - 10);
      return;
    }
    setRotation(-180);
  };

  const rotateRight = () => {
    if (rotation <= 170) {
      setRotation((prevState) => prevState + 10);
      return;
    }
    setRotation(180);
  };

  const zoomOut = () => {
    if (zoom === 1) {
      return;
    }
    setZoom((prevState) => prevState - 0.1);
  };

  const zoomIn = () => {
    if (zoom === 3) {
      return;
    }
    setZoom((prevState) => prevState + 0.1);
  };

  return (
    <Modal
      open={modalShow}
      onClose={handleCancellation}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MDBox
        bgColor="white"
        width="100%"
        maxWidth="507px"
        borderRadius="12px"
        sx={{
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.25)',
          padding: '16px 8px',
          overflow: 'scroll',
        }}
      >
        <MDTypography
          sx={{
            fontSize: '16px',
            lineHeight: '16px',
            color: '#7B809A',
            fontWeight: 'bold',
          }}
        >
          Edit image - {type}
        </MDTypography>
        <MDBox sx={{ marginTop: '12px' }}>
          {type !== 'popup' ? (
            <>
              {!file ? (
                <MDBox
                  bgColor="grey"
                  sx={{
                    width: '100%',
                    minHeight: '250px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      sx={{
                        visibility: 'hidden',
                        position: 'absolute',
                        width: 0,
                        height: 0,
                      }}
                      onChange={handleImage}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ color: 'white !important' }}
                    >
                      Upload
                    </Button>
                  </label>
                </MDBox>
              ) : (
                <DialogContent
                  dividers
                  sx={{
                    background: '#333',
                    position: 'relative',
                    height: isLandscape ? 250 : 300,
                    width: 'auto',
                  }}
                >
                  <Cropper
                    // image={photoURL || imageURL}
                    image={imageURL}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={isLandscape ? 192 / 25 : 2 / 4}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={cropComplete}
                  />
                </DialogContent>
              )}
              <MDBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
                marginTop="10px"
              >
                <MDTypography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#7B809A',
                    cursor: 'pointer',
                  }}
                  fontWeight="bold"
                  onClick={() => {
                    setFile(null);
                    // setPhotoURL(null);
                    setImageURL(null);
                    resetCrop();
                  }}
                >
                  Reset
                </MDTypography>
              </MDBox>
              <MDBox
                sx={{ margin: '0 auto', marginTop: '20px', maxWidth: '300px' }}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <RemoveIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={zoomOut}
                  />
                  <Slider
                    valueLabelDisplay="auto"
                    valueLabelFormat={zoomPercent}
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                  <AddIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={zoomIn}
                  />
                </Stack>

                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1 }}
                  alignItems="center"
                >
                  <RotateLeftIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={rotateLeft}
                  />
                  <Slider
                    aria-label="Rotate"
                    min={-180}
                    max={180}
                    value={rotation}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                  <RotateRightIcon
                    fontSize="medium"
                    cursor="pointer"
                    onClick={rotateRight}
                  />
                </Stack>
              </MDBox>
            </>
          ) : null}
          <MDBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: '57px 22px 70px 22px',
              borderTop: type !== 'popup' && '1px solid #C7CCD0',
              paddingTop: '20px',
            }}
          >
            <MDTypography
              sx={{
                fontSize: '16px',
                lineHeight: '16px',
                color: '#7B809A',
              }}
              fontWeight="bold"
            >
              Set Link:
            </MDTypography>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              disableUnderline={true}
              placeholder="http://www.redirect_link.com"
              value={redirectLink ?? ''}
              sx={{ marginLeft: '10px', flex: '1' }}
              onChange={(e) => setRedirectLink(e.target.value)}
            />
          </MDBox>
          <MDBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MDButton color="dark" onClick={handleCancellation}>
              Cancel
            </MDButton>
            <MDButton
              color="dark"
              disabled={
                // ((!photoURL || !redirectLink) && type !== 'popup') ||
                ((!imageURL || !redirectLink) && type !== 'popup') ||
                (type === 'popup' && !redirectLink)
              }
              onClick={handleSave}
            >
              Save
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default ModalEditBanner;
