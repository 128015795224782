import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import React from 'react';
import Link from '@mui/material/Link';

function ShieldMain({ title, description, children }) {
  return (
    <MDBox
      variant="gradient"
      bgColor="primary"
      borderRadius="lg"
      coloredShadow="primary"
      minHeight="195px"
      mx={2}
      mt={-3}
      p={2}
      mb={1}
      textAlign="center"
    >
      {title.toLowerCase() === 'xstream traffic' ? (
        <Link href="https://xstreamtraffic.com/" target="_blank">
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
            {title}
          </MDTypography>
        </Link>
      ) : (
        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
          {title}
        </MDTypography>
      )}
      <MDTypography variant="button" color="white" mt={1}>
        {description}
      </MDTypography>
      {children}
    </MDBox>
  );
}

export default ShieldMain;
