import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDCg2TD4Up1RtSejzWSuroUZDdHnGalKkc',
  authDomain: 'xstreamtraffic-29567.firebaseapp.com',
  projectId: 'xstreamtraffic-29567',
  storageBucket: 'xstreamtraffic-29567.appspot.com',
  messagingSenderId: '273654752529',
  appId: '1:273654752529:web:06dd6b5bd35854f67958b0',
  measurementId: 'G-JWK192Q8M0',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const db = getFirestore();
export const auth = getAuth();

const providerGoogle = new GoogleAuthProvider();
providerGoogle.setCustomParameters({ prompt: 'select_account' });

const providerFacebook = new FacebookAuthProvider();
providerFacebook.setCustomParameters({ prompt: 'select_account' });

const providerTwitter = new TwitterAuthProvider();
providerTwitter.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () =>
  signInWithPopup(auth, providerGoogle)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      return { result, credential };
    })
    .catch((error) => {
      console.log(error);
    });
export const signInWithFacebook = () =>
  signInWithPopup(auth, providerFacebook)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      return { result, credential };
    })
    .catch((error) => {
      console.log(error);
    });
export const signInWithTwitter = () =>
  signInWithPopup(auth, providerTwitter)
    .then((result) => {
      const credential = TwitterAuthProvider.credentialFromResult(result);
      return { result, credential };
    })
    .catch((error) => {
      console.log(error);
    });
