import { onAuthStateChanged, signInAnonymously, signOut } from 'firebase/auth';
import { useState, useEffect, useContext, createContext } from 'react';
import { auth } from './config';
import useAuthContext from '../../authentication/use-AuthContext';

const authContextFirebase = createContext();

export const useAuthFirebase = () => {
  return useContext(authContextFirebase);
};

const AuthContextFirebase = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const { removeSessionToken } = useAuthContext();

  const login = () => {
    return signInAnonymously(auth);
  };
  const logout = () => {
    removeSessionToken();
    return signOut(auth);
  };

  useEffect(() => {
    login();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };
  return (
    <authContextFirebase.Provider {...{ value }}>
      {children}
    </authContextFirebase.Provider>
  );
};

export default AuthContextFirebase;
