/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-xstream.png';
import { InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { userResetPassword } from 'utils/mutations/mutations';
import Loading from 'pages/user_profile/loading';
import { toast } from 'react-toastify';
import ShieldMain from '../../components/ShieldMain/ShieldMain';

function NewPass() {
  const { useResetPassword, loading } = userResetPassword();

  const [rememberMe, setRememberMe] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  let { resetToken } = useParams();

  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleClickShowPass = () => {
    setShowNewPass(!showNewPass);
  };

  const handleClickConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };

  const handleSubmit = () => {
    if (resetToken === '' || newPassword === '' || confirmPassword === '') {
      return;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const res = useResetPassword({
      variables: {
        resetToken: resetToken,
        newPassword: newPassword,
        verifyPassword: confirmPassword,
      },
    });

    toast.promise(res, {
      pending: 'In progress',
      success: {
        render() {
          navigate('/login', { replace: true });
          return 'Successful';
        },
      },
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ minWidth: '280px', minHeight: '620px' }}>
        <ShieldMain title="XStream Traffic" description="New password" />
        <MDBox px={3} py={4} component="form" role="form">
          <MDBox mb={2.5}>
            <MDInput
              size="small"
              variant="standard"
              type={showNewPass ? 'text' : 'password'}
              label="New password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={handleClickShowPass}
                    >
                      {showNewPass ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <MDBox mb={2.5}>
            <MDInput
              size="small"
              variant="standard"
              type={showConfirmPass ? 'text' : 'password'}
              label="Confirm password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={handleClickConfirmPass}
                    >
                      {showConfirmPass ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>

          <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
            <Switch checked={rememberMe} onChange={handleSetRememberMe} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              onClick={handleSetRememberMe}
              sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
            >
              &nbsp;&nbsp;Remember me
            </MDTypography>
          </MDBox>
          <MDBox mt={8} mb={1}>
            <MDButton
              variant="gradient"
              color="primary"
              fullWidth
              onClick={() => handleSubmit()}
            >
              reset password
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default NewPass;
